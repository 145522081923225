import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
	Typography,
	Container,
	Grid,
	styled,
	Button,
	Box,
} from '@mui/material';
import theme from '../../../theme';
import { backgrounds, homepageImage } from '../../../constants/staticImages';

const StyledContainer = styled(Container)(({ theme }) => ({
	backgroundSize: 'cover',
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'center',
	position: 'relative',
	padding: '0 !important',
}));

const StyledButton = styled(Button)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	backgroundColor: theme.palette.primary.info,
	color: '#FFF',
	fontSize: theme.typography.subtitle1.fontSize,
	borderRadius: '10px',
}));

const StyledBackgroundGrid = styled(Grid)(({ theme }) => ({
	height: '100%',
	width: '100%',
	position: 'absolute',
	direction: 'rtl',
	left: 0,
	top: 0,
}));
export const MapimAction = ({ page }) => {
	const currentPage = page;
	const navigate = useNavigate();
	const handleNavigate = (value) => {
		navigate(value);
	};

	return (
		<StyledContainer
			maxWidth={false}
			sx={{
				p: 0,
				marginTop: 2,
				display: 'block',
				backgroundColor: { xs: '#f0f8ffc7', md: 'transparent' },
			}}>
			<StyledBackgroundGrid
				sx={{
					direction: (currentPage.reverse && 'ltr') || 'rtl',
					zIndex: { md: 1, xs: 0 },
				}}
				container
				spacing={2}>
				{currentPage.icon && (
					<Grid
						xs={12}
						md={5}
						alignSelf={'end'}
						sx={{
							maxHeight: '100%',
							position: 'relative',
							display: 'flex',
							justifyContent: 'start',
						}}
						item>
						<Box
							component={'img'}
							sx={{
								maxHeight: '100%',
								width: '100%',
								objectFit: 'contain',
							}}
							src={homepageImage(currentPage.icon)}></Box>
					</Grid>
				)}
			</StyledBackgroundGrid>
			<Grid
				item
				xs={12}
				position={'relative'}
				sx={{
					background: `${theme.palette.primary.info}`,
					color: '#FFF',
				}}
				padding={3}>
				<Typography
					variant='h4'
					textAlign={(currentPage.reverse && 'end') || 'start'}
					margin={5}>
					{currentPage.object.title}
				</Typography>
				<Typography
					variant='h5'
					textAlign={(currentPage.reverse && 'end') || 'start'}
					margin={5}>
					{currentPage.object.description.split('|').map((text, idx) => (
						<Box key={idx}>
							{text}
							<br />
						</Box>
					))}
				</Typography>
			</Grid>
			<Grid
				item
				xs={12}
				sx={{
					marginTop: 5,
					marginBottom: 2,
					p: 2,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}>
				{currentPage.action && (
					<StyledButton
						onClick={() => handleNavigate(currentPage.action.value)}
						sx={{ px: 3, py: 1, zIndex: 11 }}
						variant='contained'
						size='large'>
						<Typography variant='h6'>{currentPage.action.libelle}</Typography>
					</StyledButton>
				)}
			</Grid>
		</StyledContainer>
	);
};
