import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { PRIMARY } from '../../../theme/utils/color';
import IconButton from '@mui/material/IconButton';
import { Remove } from '@mui/icons-material';
import { Add } from '@material-ui/icons';

const styles = {
	button: {
		padding: '10px 10px',
		cursor: 'pointer',
		'&:hover': {
			borderBottom: `5px solid ${PRIMARY.green}`,
		},
	},
};

/**
 * {
 *     name: string         | Button name,
 *     element: JSX.Element | An element to display
 * }
 * @returns {JSX.Element}
 * @constructor
 */
export function PageManagerComponent({ items }) {
	const [page, setPage] = useState(0);

	return (
		<>
			<Box
				sx={{
					display: 'flex',
					margin: '30px 20px',
					borderBottom: '2px solid ' + PRIMARY.green,
					maxWidth: 896,
				}}>
				{items.map((item, i) => (
					<PageButton
						key={i}
						name={item.name}
						page={i}
						currentPage={page}
						setPage={setPage}
					/>
				))}
			</Box>
			<Box sx={{ marginTop: '20px', maxWidth: 896 }}>{items[page].element}</Box>
		</>
	);
}

function PageButton({ page, name, currentPage, setPage }) {
	return (
		<Typography
			sx={{
				...styles.button,
				borderBottom: currentPage === page ? '5px solid ' + PRIMARY.green : '',
			}}
			onClick={() => setPage(page)}
			variant={'h5'}>
			{name}
		</Typography>
	);
}

/**
 * {
 *     title: string    | The title of a course
 *     duration: number | Duration in seconds
 * }
 * @returns {JSX.Element}
 * @constructor
 */
export function ContentBox({ chapter, courseDetail }) {
	const [open, setOpen] = useState(false);

	return (
		<Box
			sx={{
				margin: '10px 10px 20px 10px',
				padding: '10px 10px 10px 40px',
				border: '2px solid black',
				position: 'relative',
			}}>
			<IconButton
				sx={{ position: 'absolute', top: '0px', left: '0px' }}
				onClick={() => setOpen(!open)}>
				{open ? (
					<Remove style={{ color: PRIMARY.green, height: '32px' }}></Remove>
				) : (
					<Add style={{ color: PRIMARY.green, height: '32px' }}></Add>
				)}
			</IconButton>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					marginBottom: open ? '30px' : '',
				}}>
				<Typography variant={'h5'}>{chapter}</Typography>
			</Box>
			{open
				? courseDetail.map((course, i) => {
						return (
							<Box
								sx={{ display: 'flex', justifyContent: 'space-between' }}
								key={i}>
								<Typography>{course.lesson_title}</Typography>
							</Box>
						);
				  })
				: ''}
		</Box>
	);
}

function convertDuration(seconds) {
	const minutes = Math.floor(seconds / 60);
	const finalSeconds = seconds % 60;
	return `${minutes > 9 ? '' : '0'}${minutes}:${
		finalSeconds > 9 ? '' : '0'
	}${finalSeconds}`;
}
