import React, { useEffect, useRef } from 'react';
import { Grid, Typography, Container, styled, Box } from '@mui/material';
import { register } from 'swiper/element/bundle';

const StyledContainer = styled(Container)(({ theme }) => ({
	backgroundSize: 'cover',
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'center',
	position: 'relative',
	marginBottom: theme.spacing(5),
	backgroundColor: theme.palette.common.white,
	color: theme.palette.text.primary,
	[theme.breakpoints.down('sm')]: {
		position: 'static',
		margin: '20px 0',
	},
}));

const StyledContent = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	width: '100%',
}));

const StyledGrid = styled(Grid)(({ theme }) => ({
	padding: '20px',
}));

const StarRating = ({ rating }) => {
	const stars = '★'.repeat(rating);
	return <span>{stars}</span>;
};
const StyledNumberImage = styled(Box)(({ theme }) => ({
	width: '66px',
	height: '66px',
	background: 'white',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	color: 'black',
	fontSize: '24px',
	marginRight: '80px',
	marginTop: '50px',
	marginBottom: '50px',
	marginLeft: '-35px',
}));
export const MapimTestimonia = ({ page }) => {
	const currentPage = page;
	const swiperRef = useRef(null);

	useEffect(() => {
		const params = {
			slidesPerView: '1',
			spaceBetween: '30',
			autoplayDelay: '2500',
			loop: true,
			autoplayDisableOnInteraction: false,
			breakpoints: {
				768: {
					slidesPerView: '3',
				},
			},
		};

		// Assign it to swiper element
		Object.assign(swiperRef.current, params);
		// initialize swiper
		register();
		swiperRef.current.initialize();
	}, []);
	return (
		swiperRef && (
			<StyledContainer
				maxWidth={false}
				sx={{ paddingLeft: '0' }}
				id='testimonial'>
				<StyledContent>
					<Typography variant='h4' mb={5} fontWeight='bold'>
						{currentPage.object.title.upper_sentence}
					</Typography>

					<Typography variant='h6' fontWeight='bold'>
						{currentPage.object.description.sentence}
					</Typography>
				</StyledContent>
				<swiper-container init={false} ref={swiperRef}>
					{currentPage.object.description1.map((item, index) => (
						<swiper-slide
							key={item + '_' + index}
							style={{
								borderRadius: '5px',
								paddingLeft: '0 !important',
								padding: '40px',
								textAlign: 'center',
								width: '45%',
							}}>
							<div
								id={'description-' + index}
								style={{
									border: '1px solid #ccc',
									borderRadius: '5px',
								}}>
								<Typography variant='h6' sx={{ mb: 1 }}>
									<StarRating rating={5} />
								</Typography>
								<Typography fontWeight='bold'>{item.sentence}</Typography>
								<Typography>{item.sentence2}</Typography>
								<StyledNumberImage>
									<img src={'/icons/quote.png'} alt='icon' />
								</StyledNumberImage>
								<div
									style={{
										backgroundImage: 'url(/icons/fond_avis.png)',
										backgroundSize: '65% 100%',
										backgroundRepeat: 'no-repeat',
										backgroundColor: 'rgba(138,148,1,0.18)',
										padding: '10px',
									}}>
									<Typography margin={5} sx={{ fontStyle: 'italic' }}>
										{item.sentence3}
									</Typography>
								</div>
							</div>
						</swiper-slide>
					))}
					{currentPage.object.description1.map((item, index) => (
						<swiper-slide
							key={item + '_' + index}
							style={{
								borderRadius: '5px',
								paddingLeft: '0 !important',
								padding: '40px',
								textAlign: 'center',
								width: '45%',
							}}>
							<div
								id={'description-' + index}
								style={{
									border: '1px solid #ccc',
									borderRadius: '5px',
								}}>
								<Typography variant='h6' sx={{ mb: 1 }}>
									<StarRating rating={5} />
								</Typography>
								<Typography fontWeight='bold'>{item.sentence}</Typography>
								<Typography>{item.sentence2}</Typography>
								<StyledNumberImage>
									<img src={'/icons/quote.png'} alt='icon' />
								</StyledNumberImage>
								<div
									style={{
										backgroundImage: 'url(/icons/fond_avis.png)',
										backgroundSize: '65% 100%',
										backgroundRepeat: 'no-repeat',
										backgroundColor: 'rgba(138,148,1,0.18)',
										padding: '10px',
									}}>
									<Typography margin={5} sx={{ fontStyle: 'italic' }}>
										{item.sentence3}
									</Typography>
								</div>
							</div>
						</swiper-slide>
					))}
				</swiper-container>
			</StyledContainer>
		)
	);
};
