export const LOGIN = [
	{
		label: 'Votre adresse email',
		name: 'email',
		required: true,
		type: 'email',
		props: {
			customEndAdornment: 'Email',
		},
	},

	{
		label: 'Mot de passe',
		name: 'password',
		required: true,
		type: 'password',
		props: {
			customEndAdornment: 'Visibility',
		},
	},
];

export const REGISTER = [
	{
		label: 'Votre nom',
		name: 'name',
		required: true,
		type: 'text',
	},
	{
		label: 'Votre prenom',
		name: 'lastname',
		required: true,
		type: 'lastname',
	},
	{
		label: 'Votre email',
		name: 'email',
		required: true,
		type: 'text',
	},

	{
		label: 'Mot de passe',
		name: 'password',
		required: true,
		type: 'password',
		props: {
			customEndAdornment: 'Visibility',
		},
	},
	{
		label: 'Confirmation Mot de passe',
		name: 'password_confirm',
		required: true,
		type: 'password',
	},
	{
		label: 'Votre Téléphone',
		name: 'phone_number',
		required: true,
		type: 'number',
		props: {
			customEndAdornment: 'Email',
		},
	},
	{
		label: "Nom de l'enterprise",
		name: 'enterprise_name',
		required: true,
		type: 'test',
	},
];

export const CONTACT = [
	{
		label: 'Votre Nom',
		name: 'name',
		required: true,
		type: 'text',
	},
	{
		label: 'Votre Téléphone',
		name: 'telephone',
		required: true,
		type: 'telephone',
	},
	{
		label: 'Votre adresse email',
		name: 'email',
		required: true,
		type: 'email',
	},
	{
		label: 'Message',
		name: 'message',
		required: true,
		type: 'longtext',
	},
];
