import React from "react";
import { Box, Grid } from "@mui/material";
import { backgrounds } from '../../../constants/staticImages';

export const CustomImageGrid = ({data}) => {
    return (
        <Grid container style={{display: "flex"}} justifyContent="center" alignItems="center" >
            {
                data.map((element, index) => (
                    <Grid key={index} item xs={12} md={4} style={{display: "flex"}} justifyContent="center" alignItems="center" >
                        <Box
                            component={"img"}
                            sx={{
                                width: "100%",
                                objectFit:"cover"
                            }}
                            src={backgrounds(element)}
                        >
                        </Box>
                    </Grid>
                ))
            }
        </Grid>
    )
}