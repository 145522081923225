export const icons = (icon) => {
	return `${process.env.REACT_APP_BACKEND_URL}/icons/${icon}`;
};

export const backgrounds = (filename) => {
	return `${process.env.REACT_APP_BACKEND_URL}/background/${filename}`;
};

export const images = (filename) => {
	return `${process.env.REACT_APP_BACKEND_URL}${filename}`;
};

export const profileImage = (filename) => {
	return `${process.env.REACT_APP_FILE_URL}/storage/${filename}`;
};

export const staticimages = (filename) => {
	return `https://file.mapim-immo.fr/static/image/icon/common/${filename}`;
};

export const backgroundImage = (filename) =>
	`https://file.mapim-immo.fr/static/image/icon/common/${filename}`;

export const homepageImage = (filename) =>
	`https://file.mapim-immo.fr/static/image/icon/homepage/${filename}`;

export const homeImageBackground = (filename) =>
	`https://file.mapim-immo.fr/static/image/background/homepage/${filename}`;
