import { Box, Grid, Stack, Typography } from '@mui/material';
import { backgrounds, profileImage } from '../../constants/staticImages';
import LinearProgressWithLabel from '../../components/utils/modules/LinearProgressWithLabel';
import React, { useEffect, useState } from 'react';

import { styles } from './CoursesPage';
import { PRIMARY } from '../../theme/utils/color';
import IconButton from '@mui/material/IconButton';
import { ExpandLess } from '@material-ui/icons';
import { RestartAlt } from '@mui/icons-material';

import { useQuizz } from './../../services/useQuizz';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../features/useLoading';

function QuizBox({
	totalQuestions,
	questionAchieved,
	courseName,
	courseImage,
	restart,
	formationUuid,
}) {
	const percentAchieved = Math.round((questionAchieved / totalQuestions) * 100);

	return (
		<Grid
			container
			wrap='wrap'
			style={{
				display: 'flex',
				flexWrap: 'wrap',
				border: '2px solid black',
				borderRadius: '10px',
				maxWidth: '1080px',
				marginTop: '30px',
				position: 'relative',
			}}>
			<Grid
				item
				xs='auto'
				sx={{
					borderRight: '1px solid gray',
					display: 'flex',
					alignContent: 'center',
					...styles.imageContainer,
				}}>
				<Box
					component={'img'}
					sx={styles.image}
					alt='courses-image'
					src={profileImage(courseImage)}
					padding={1}
				/>
			</Grid>
			<Grid item xs={8} sx={styles.rightContainer}>
				<Box sx={styles.rightContent}>
					<Typography sx={styles.text} variant='h4'>
						{courseName}
					</Typography>
					<Box
						sx={{
							display: 'flex',
							gap: '4px 32px',
							flexWrap: 'wrap',
							margin: '10px 0',
						}}>
						<Typography sx={styles.text} variant='p'>
							Total de questions: {totalQuestions}
						</Typography>
						<Typography sx={styles.text} variant='p'>
							Questions réussies: {questionAchieved}/{totalQuestions}
						</Typography>
					</Box>
					<LinearProgressWithLabel
						variant='determinate'
						value={percentAchieved}
						sx={{
							height: 10,
							borderRadius: 5,
							width: '100%',
							backgroundColor: 'rgba(90, 90, 90, .2)',
							'& .MuiLinearProgress-bar': {
								backgroundColor:
									percentAchieved > 50 ? PRIMARY.green : '#d01a1a',
							},
						}}
						label={
							percentAchieved === 100
								? `100% de terminé`
								: `${percentAchieved}% de réussite`
						}
					/>
				</Box>
			</Grid>

			{percentAchieved !== 100 && (
				<IconButton
					onClick={() => restart(formationUuid)}
					sx={{ position: 'absolute', right: '10px', bottom: '50px' }}>
					<RestartAlt
						sx={{
							borderRadius: '50%',
							color: 'white',
							backgroundColor: 'gray',
							fontSize: '32px',
							padding: '2px',
						}}
					/>
				</IconButton>
			)}
		</Grid>
	);
}

export function QuizPage() {
	const [myQuizz, setMyQuizz] = useState();
	const restart = (formationUuid) => {
		window.location.href = '/dashboard/quizz/' + formationUuid;
	};
	const getMyQuizz = async () => {
		const quizzByFormation = [];
		useQuizz.myQuizz().then(({ data }) => {
			console.log(data);
			data.forEach((quizz) => {
				const formation = quizz.question.formation;
				if (!quizzByFormation[formation.id]) {
					quizzByFormation[formation.id] = [];
					quizzByFormation[formation.id].push(quizz);
				} else {
					quizzByFormation[formation.id].push(quizz);
				}
			});
			const quizzByFormations = quizzByFormation.filter(
				(quizz) => quizz != null
			);
			setMyQuizz(quizzByFormations);
		});
	};
	const dispatch = useDispatch();
	useEffect(() => {
		if (!myQuizz) {
			getMyQuizz();
			setTimeout(() => {
				dispatch(setLoading(false));
			}, 1500);
		}
	});
	return (
		myQuizz && (
			<Box sx={{ padding: '32px 48px' }}>
				<Typography sx={{ marginBottom: '5px' }} variant={'h4'}>
					Mes Quizz
				</Typography>
				<Stack>
					{myQuizz
						.sort((q1, q2) => q1.order - q2.order)
						.map((quizz, i) => (
							<QuizBox
								key={i}
								restart={restart}
								formationUuid={quizz[0].question?.formation.uuid}
								courseName={quizz[0].question?.formation.title}
								questionAchieved={quizz.filter((q) => q.validated).length}
								totalQuestions={quizz.length}
								courseImage={quizz[0].question?.formation.photo}
							/>
						))}
				</Stack>
			</Box>
		)
	);
}
