export const homePageData = () => {
	return [
		{
			id: 1,
			reverse: false,
			type: 'information',
			image: {
				side_upper_image: 'illustration_mapim_principal_1.png',
			},
			imageScale: 1,
			icon: 'icon_logiciel.png',
			object: {
				image: null,
				title: {
					upper_sentence: 'VOTRE PLATEFORME',
					lower_sentence: 'LOI ALUR EN LIGNE',
				},
				description: [
					{
						type: `h4`,
						sentences: `
                        Votre référence en terme de formation Loi ALur !
                    `,
					},
					{
						type: `h6`,
						sentences: `
                        Profitez de nombreux modules de formation dispensés en e-learning,
						accessible à tout moment depuis une simple connexion internet.
                    `,
					},
					{
						type: `h6`,
						sentences: `
                        Découvrez de nombreux outils au sein de votre Espace utilisateur vous permettant de gérer au mieux votre formation.
                    `,
					},
					{
						type: `h6`,
						sentences: `
                        Nous faisons le choix de vous proposer des formations qualitatives,
						à prix réduits afin de faire profiter le plus grand nombre de
						professionnels.
                    `,
					},
					{
						type: `h6`,
						sentences: `
                        MAPIM Formation, c'est aussi un SAV de qualité, muni d'un espace
						dédié à vos questions ou remarques, visibles également par les autres
						professionnels de l'immobilier.
                    `,
					},
				],
			},
		},
		{
			id: 2,
			reverse: false,
			type: 'client_satisfaction',
			remote_icon: false,
			icon: 'certificat-de-garantie.png',
			image: 'satisfaction.png',
			object: {
				title: {
					upper_title: 'La satisfaction client',
					lower_title: 'Au coeur de notre métier',
				},
				description: [
					{
						type: 'h4',
						sentences:
							'Chez MAPIM Formation, nous mettons notre coeur et notre savoir pour vous satisfaire !',
					},
					{
						type: 'h6',
						sentences:
							'Parce que nous cherchons constamment à nous améliorer et perfectionner nos services, votre avis compte énormément pour nous!',
					},
				],

				description2: [
					{
						type: 'h6',
						sentences:
							"A ce titre, nous sommes à l'écoute de vos besions en terme de thématiques afin de vous apporter toujours plus de contenus de formation.",
					},
				],
				description3: [
					{
						percentage: 84,
						sentences: 'Diversité de contenu',
					},
					{
						percentage: 89,
						sentences: 'Clareté des videos',
					},
					{
						percentage: 97,
						sentences: 'Service client',
					},
					{
						percentage: 90,
						sentences: 'Satisfaction client',
					},
				],
			},
		},
		{
			id: 3,
			reverse: true,
			type: 'information',
			image: {
				side_upper_image: 'illustration_mapim_principal_contenu_varié.png',
			},
			imageScale: 1.1,
			icon: 'icon_client.png',
			remote_icon: false,
			object: {
				image: null,
				title: {
					upper_sentence: 'POURQUOI CHOISIR',
					lower_sentence: 'MAPIM FORMATION ?',
				},
				description: [
					{
						type: `h4`,
						sentences: `
                        Un contenu et varié !
		            `,
					},
					{
						type: `h6`,
						sentences: `
		                Avec plus de 50h de formations certifiées réparties en une vingtaine de modules, faites le choix parmis les thématiques qui vous correspondent.
		            `,
					},
					{
						type: `h6`,
						sentences: `
		                Retrouvez bon nombre de thèmes répondant à vos besoins, de la vente classique jusqu'à l'immobilier pro, en passant par la gestion, l'estimation, la prospection, la prise de mandat, la photographie, la règlementation, et bien d'autres...
		            `,
					},
				],
			},
		},
		{
			id: 4,
			reverse: false,
			imageScale: 1.25,
			type: 'information',
			image: {
				side_upper_image: 'illustration_plateforme_tarifs3.png',
			},
			icon: 'icon_web.png',
			object: {
				image: null,
				title: {},
				description: [
					{
						type: `h4`,
						sentences: `
		                Votre formation à tarif réduit !
		            `,
					},
					{
						type: `h6`,
						sentences: `
		                Nous choisir, c'est vous garantir des tarifs avantageux et abordables pour la formation de vos équipes, et ce, toute l'année.
		            `,
					},
					{
						type: `h6`,
						sentences: `
		                MAPIM formation se positionne aujourd'hui comme étant l'un des organismes de formaiton Loi ALur parmis les moins chers du marché, tout en garantissant un service de formation et un SAV de qualité !
		            `,
					},
				],
			},
		},
		{
			id: 5,
			reverse: true,
			type: 'information',
			imageScale: 1.25,
			image: {
				side_upper_image:
					'illustration_mapim_principal_suivi_plusieurs_modules.png',
			},
			icon: 'icon_formation.png',
			object: {
				image: null,
				title: {},
				description: [
					{
						type: `h4`,
						sentences: `
		                Suivez votre formation à votre rythme !
		            `,
					},
					{
						type: `h6`,
						sentences: `
		               Grâce à votre espace dédié, décidez de suivre vos modules un à un, ou plusieurs à la fois !
		            `,
					},
					{
						type: `h6`,
						sentences: `
		                Reprenez la lecture de vos vidéos là où vous en étiez en décidant de poursuivre votre module de formation.
		            `,
					},
				],
			},
		},
		{
			id: 6,
			reverse: false,
			type: 'information',
			imageScale: 1.25,
			image: {
				side_upper_image: 'illustration_mapim_principal_suivi_quiz1.png',
			},
			icon: null,
			object: {
				image: null,
				title: {},
				description: [
					{
						type: `h4`,
						sentences: `
		                Gérez facilement vos heures de formation !
		            `,
					},
					{
						type: `h6`,
						sentences: `
		               Depuis votre interface, et à tout moment de votre formation, ne perdez pas de vue le nombre d'heures déjà validé et celles restantes à valider.
		            `,
					},
					{
						type: `h6`,
						sentences: `
		                Après chaque Quiz effectué et réussi, votre décompte d'heures de formation se met automatiquement à jour... Idéal pour également orienter vos choix de modules et valider ainsi votre formation Loi Alur !
		            `,
					},
				],
			},
		},

		{
			id: 7,
			reverse: true,
			type: 'information',
			imageScale: 1,
			image: {
				side_upper_image: 'illustration_mapim_principal_suivi_Q_R2.png',
			},
			icon: 'icon_formation.png',
			object: {
				image: null,
				title: {},
				description: [
					{
						type: `h4`,
						sentences: `
		                Posez vos questions depuis n'importe quel module !
		            `,
					},
					{
						type: `h6`,
						sentences: `
		               Une question vous vient à l'esprit lors de la lecture d'un module ?
					   Posez-là directement depuis l'espace Q&R dédié. Nos équipes se feront un plaisir d'y répondre !
		            `,
					},
					{
						type: `h6`,
						sentences: `
		                Et parce que vos interrogations peuvent également être celles d'autres professionnels, retrouvez un récapitulatif de toutes les questions posées depuis l'onglet Q&R, disponible sur le descriptif des modules ou à tout moment, depuis la lecture des leçons.
		            `,
					},
				],
			},
		},
		{
			id: 8,
			reverse: false,
			type: 'information',
			imageScale: 1.25,
			image: {
				side_upper_image: 'illustration_mapim_principal_suivi_attestation1.png',
			},
			icon: null,
			object: {
				image: null,
				title: {},
				description: [
					{
						type: `h4`,
						sentences: `
		                Effectuez vos Quiz et validez vos heures !
		            `,
					},
					{
						type: `h6`,
						sentences: `
		               Réalisez les Quiz de fin de modules afin de valider vos heures de formation Loi Alur, et obtenez directement votre Attestation depuis l'onglet dédié de votre Espace, téléchargeable sous format PDF, et prête à être envoyée à la CCI dont vous dépendez.
		            `,
					},
					{
						type: `h6`,
						sentences: `
		                Vous n'êtes pas parvenu à valider l'un des Quiz ou n'êtes pas satisfait de vos résultats?
						Pas de panique ! Relancez le Quiz facilement afin de vous améliorer. La meilleure note obtenu sera ainsi prise en compte.
		            `,
					},
				],
			},
		},
		{
			id: 9,
			reverse: true,
			type: 'client_testimonial',
			// image: {
			// 	side_upper_image: 'illustration_mapim_principal_suivi_quiz2.png',
			// 	side_upper_image: 'illustration_mapim_principal_suivi_attestation1.png',
			// },
			remote_icon: false,
			icon: 'certificat-de-garantie.png',
			image: 'satisfaction.png',
			object: {
				image: null,
				title: {
					upper_sentence: 'TEMOIGNAGES CLIENTS',
				},
				description: {
					sentence: 'Parce que votre avis compte pour nous ...',
				},
				description1: [
					{
						sentence: 'Cécilie Fernand',
						sentence1: 'Mandataire Indépendant',
						sentence2: 'Mandataire Indépendant',
						start: 4,
						sentence3:
							'Excellent organisme de formation Loi Alur, avec la possibilité de visualiser les heures restantes à valider, ce qui facilite grandement les choses !',
					},
					{
						sentence: 'Enzo Gerard',
						sentence1: 'etape 2',
						start: 5,
						sentence2: 'Agent Immobilier',
						sentence3:
							"Je recommande cet organisme, beaucoup de thèmes différents pour plaire au plus grand nombre ! Les cours simples et claires, apportant beaucoup d'informations.",
					},
					{
						sentence: 'Damien Hauet',
						sentence1: 'Le lorem ipsum est, en imprimerie,',
						sentence2: "Gérant d'agence",
						sentence3:
							'Des vidéos très bien tournées et claires. La possibilité de récupérer son attestation en un clic... Rien de plus simple ! Je ne peux que recommander !',
					},
				],
			},
		},
		{
			id: 10,
			reverse: true,
			type: 'information',
			image: {
				side_upper_image: 'illustration_mapim_principal_suivi_comment.png',
			},
			icon: null,
			object: {
				image: null,
				title: {
					upper_sentence: 'MAPIM FORMATION',
					lower_sentence: 'COMMENT ÇA MARCHE ?',
				},
				description: [
					{
						type: `h4`,
						sentences: `
		                Une simplicité d'utilisation...
		            `,
					},
					{
						type: `h6`,
						sentences: `
		               Inscrivez-vous rapidement et simplement depuis l'onglet "Mon Espace".
		            `,
					},
					{
						type: `h6`,
						sentences: `
                        Optez pour l'une de nos offres* et passer commande via notre système de paiement sécurisé.
		            `,
					},
					{
						type: `h6`,
						sentences: `
                        Rendez-vous dans "Mon Espace" pour pouvoir gérer efficacement vos modules, Quiz, Q&R, Attestations et factures.
		            `,
					},
					{
						type: `h6`,
						sentences: `
                        Depuis la page "Nos modules", sélectionnez le ou les mpodules quivous intéressent, suivez ensuite les leçons dédiées et validez vos heures en réalisant les Quiz de fin de modules.
		            `,
					},
					{
						type: `h6`,
						sentences: `
                        Une fois l'intégralité de vos heures validées, obtenez automatiquement votre Attestation certifiée conforme, prête à être envoyée à la CCI dont vous dépendez.
		            `,
					},
					{
						type: `h6`,
						sentences: `
                        Besoin d'avantage d'heures? Une fois vos premières heures validées, passez à nouveau commande et suivez les mêmes étapes.
		            `,
					},
				],
			},
		},
		{
			id: 11,
			reverse: false,
			type: 'action_type',
			remote_icon: false,
			icon: 'accord.png',
			buttonText: 'Voir nos offres',
			buttonLink: '/offers',
			image: 'satisfaction.png',
			object: {
				title: 'Inscrivez vous dès maintenant! ',
				description1: {
					type: 'h4',
					sentences: 'Profitez dès à présent de nos offres uniques...',
				},
				description2: {
					type: 'h4',
					sentences:
						'Et optez pour MAPIM formation pour vous former à la Loi Alur !',
				},
			},
		},
	];
};
