import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function LoadingComponent() {
	return (
		<Box sx={{ position: 'relative' }}>
			<CircularProgress
				variant='indeterminate'
				size={'12rem'}
				thickness={0.5}
				sx={{
					animationDuration: '2000ms',
				}}
			/>
			<CircularProgress
				variant='indeterminate'
				sx={{
					position: 'absolute',
					top: '50%',
					left: '50%',
					animationDuration: '1000ms',
					transform: 'translate(-50%,-50%) !important',
				}}
				color='error'
				size={'8rem'}
				thickness={0.5}
			/>
		</Box>
	);
}
