import axios from 'axios';
import { AuthStorage } from '../utils/AuthStorage';

export const createHttp = () => {
	const token = AuthStorage.isAuth()?.token;
	const instance = axios.create({
		baseURL: process.env.REACT_APP_BASE_API_URL,
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
	return instance;
};
