import React from "react";
import { Typography, Box, Grid, styled } from "@mui/material";

const StyledBox = styled(Box)(({theme}) => ({
    boxShadow: `inset 0px 0px 5px -2px ${theme.palette.primary.main}`,
    width: '100%',
    ":hover":{
        background: theme.palette.secondary.main,
        opacity: 0.2
    }
}))
export const CustomGrid = ({data, height}) => {
    return (
        <StyledBox style={{height: height}}>
            <Grid container style={{display: "flex"}} justifyContent="center" alignItems="strech" >
                <Grid item xs={12}  style={{display: "flex"}} justifyContent="center" alignItems="center" >
                    <Typography variant="h6" sx={{ my: 2 }}>
                        {data.title}
                    </Typography>
                </Grid>
            </Grid>
        </StyledBox>
    )
}