import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { AuthStorage } from '../../utils/AuthStorage';
import personImage from '../../assets/imageDefault.png';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../features/useLoading';
import { CameraAltOutlined } from '@material-ui/icons';
import { createHttp } from '../../api/http';
import { profileImage } from '../../constants/staticImages';

function InformationUser() {
	const userInfos = AuthStorage.isAuth();
	const dispatch = useDispatch();
	const [isUpdating, setIsUpdating] = useState(false);
	const timer = setTimeout(() => {
		dispatch(setLoading(false));
	}, 1500);
	const [file, setFile] = useState();

	if (!userInfos) {
		return (
			<Box
				sx={{
					padding: '20px',
					border: '2px solid black',
					borderRadius: '15px',
					position: 'relative',
					marginBottom: '20px',
				}}>
				<Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>
					Information :{' '}
				</Typography>
				<Typography>N/A</Typography>
			</Box>
		);
	}

	const infosUser = userInfos.user || {};
	const cardNumber = infosUser.card_number || 'N/A';
	const maskedCardNumber = cardNumber.substring(0, 4) + '**** **** **** ****';
	const handleImageChange = (e) => {
		setFile(e.target.files[0]);
	};
	const updateProfilPhoto = async () => {
		setIsUpdating(true);
		dispatch(setLoading(true));
		const http = createHttp();
		const body = new FormData();
		body.append('photo', file);
		const response = await http.post('/user/update', body).then((response) => {
			dispatch(setLoading(true));
			return response;
		});
		dispatch(setLoading(true));
		const authStorage = AuthStorage.isAuth();
		console.log(response);
		const newAuthStorage = {
			token: authStorage.token,
			user: response.data,
		};
		AuthStorage.authenticate(newAuthStorage, () => {
			window.location.reload();
		});
	};
	return (
		<Grid
			container
			sx={{
				padding: '20px',
				border: '2px solid black',
				borderRadius: '15px',
				position: 'relative',
				marginBottom: '20px',
			}}>
			<Grid item xs={12} sm={6}>
				<Typography>
					<strong> Nom : </strong>
					{infosUser.name || 'N/A'}
				</Typography>
				<Typography>
					<strong> Prenom : </strong>
					{infosUser.lastname || 'N/A'}
				</Typography>
				<Typography>
					<strong> Email : </strong>
					{infosUser.email || 'N/A'}
				</Typography>
				<Typography>
					<strong> Phone : </strong>
					{infosUser.phone_number || 'N/A'}
				</Typography>
				<Typography>
					<strong> Nom entreprise : </strong>
					{infosUser.enterprise_name || 'N/A'}
				</Typography>
			</Grid>
			<Grid item xs={12} sm={4}>
				<div style={{ textAlign: 'center' }}>
					<img
						src={infosUser.photo ? profileImage(infosUser.photo) : personImage}
						alt='Person'
						style={{ width: '250px', height: '250px' }}
					/>
				</div>
				<div style={{ textAlign: 'center' }}>
					<label htmlFor='fileInput'>
						<CameraAltOutlined
							style={{ cursor: 'pointer' }}
							title='Changer votre photo de profil'
						/>
					</label>
					<input
						type='file'
						id='fileInput'
						style={{ display: 'none' }}
						onChange={(e) => handleImageChange(e)}
					/>
				</div>
				<div style={{ textAlign: 'center' }}>
					{file && (
						<>
							<img
								id='blah'
								src={URL.createObjectURL(file)}
								alt='Preview'
								style={{ width: '100%' }}
							/>
							<button disabled={isUpdating} onClick={() => updateProfilPhoto()}>
								Changer ma photo de profil{' '}
								{isUpdating && <CircularProgress size={'1rem'} />}
							</button>
						</>
					)}
				</div>
			</Grid>
		</Grid>
	);
}

export const Profile = () => {
	return (
		<Box sx={{ padding: '32px 48px' }}>
			<Typography sx={{ marginBottom: '20px' }} variant={'h4'}>
				Mon profil
			</Typography>
			<InformationUser />
		</Box>
	);
};
