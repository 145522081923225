import React from 'react';
import { useState, useEffect } from 'react';
import { Typography, Grid, Paper, Button, Container } from '@mui/material';
import {
	Elements,
	useElements,
	useStripe,
	CardNumberElement,
	CardExpiryElement,
	CardCvcElement,
	CardElement,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { usePlan } from '../../services/usePlan';
import { useNavigate } from 'react-router-dom';
import { AuthStorage } from '../../utils/AuthStorage';
import { useSubscription } from '../../services/useSubscription';
import { toast } from 'react-toastify';
import { setLoading } from '../../features/useLoading';
import { useDispatch, useSelector } from 'react-redux';
import { useOrder } from '../../services/useOrder';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
const cardElementOptions = {
	style: {
		base: {
			fontSize: '30px',
			color: '#424770',
			letterSpacing: '0.025em',
			fontFamily: 'Source Code Pro, monospace',
			'::placeholder': {
				color: '#aab7c4',
			},
		},
		invalid: {
			color: '#9e2146',
		},
	},
	hidePostalCode: true,
};

const CheckoutForm = ({ total, user, plans, totalduration, totalQuantity }) => {
	const navigate = useNavigate();
	const stripe = useStripe(process.env.REACT_APP_STRIPE_KEY);
	const elements = useElements();
	const [clientSecret, setClientSecret] = useState('');
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');

	useEffect(() => {
		const {
			name: storedName,
			email: storedEmail,
			phone_number: storedPhone,
		} = user.user;
		setName(storedName || '');
		setEmail(storedEmail || '');
		setPhone(storedPhone || '');
	}, [user.user]);

	const dispatch = useDispatch();
	useEffect(() => {
		setTimeout(() => {
			dispatch(setLoading(false));
		}, 1500);
	}, []);

	const handleSubmit = async (event) => {
		event.preventDefault();
		dispatch(setLoading(true));

		if (!stripe || !elements) {
			return;
		}

		const cardElement = elements.getElement(CardElement);

		if (!cardElement) {
			dispatch(setLoading(false));
			toast.warning('Veuillez entrer les details de la carte', {
				position: 'top-right',
				autoClose: 5000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: 'light',
			});
			return;
		}

		const result = await stripe.createPaymentMethod({
			type: 'card',
			card: cardElement,
		});

		if (result.error) {
			console.log(result.error.message);
			dispatch(setLoading(false));
		} else {
			try {
				const response = await usePlan.checkoutPlan({
					payment_method: result.paymentMethod.id,
					amount: total,
					email: email,
					name: name,
					phone: phone,
				});
				setClientSecret(response.data.client_secret);
				const confirmCardPayment = await stripe.confirmCardPayment(
					response.data.client_secret,
					{
						payment_method: {
							card: cardElement,
						},
					}
				);

				if (confirmCardPayment.error) {
					dispatch(setLoading(false));
					toast.error("Votre paiement n'a pas aboutie, veuillez reessayer ", {
						position: 'top-right',
						autoClose: 5000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: 'light',
					});
				} else {
					await useSubscription
						.subscribePlan({
							plan_subscription: plans,
							token: response.data.client_secret,
						})
						.then(async () => {
							await useOrder
								.storeOrder({
									plans: plans,
									total_amount: total,
									total_duration: totalduration,
									total_quantity: totalQuantity,
								})
								.then(() => {
									localStorage.removeItem('cartItems');
									localStorage.removeItem('totalAmount');
									localStorage.removeItem('totalDuration');
									localStorage.removeItem('totalQuantity');
									dispatch(setLoading(false));
									toast.success('Votre paiement est reussi !', {
										position: 'top-right',
										autoClose: 2000,
										hideProgressBar: true,
										closeOnClick: true,
										pauseOnHover: true,
										draggable: true,
										progress: undefined,
										theme: 'light',
									});
									// // Mettre la navigation à la toute fin
									window.location.href = '/dashboard';
								})
								.catch(() => {
									dispatch(setLoading(false));
								});
						});
				}
			} catch (error) {
				console.error(error);
			}
		}
	};

	return (
		<form
			onSubmit={handleSubmit}
			style={{
				padding: '20px',
				border: '2px solid grey',
				boxShadow: '10px 10px 10px',
				marginBottom: '10px',
				width: '100%',
			}}>
			{/* <CardNumberElement
       options={{
        showIcon: true,
        style:{cardElementOptions}
      }}

     />
     />
				<CardExpiryElement></CardExpiryElement>
    <CardCvcElement /> */}
			<div style={{ flexBasis: '100%' }}>
				<CardElement options={cardElementOptions} />
			</div>

			<Button type='submit' variant='contained' disabled={!total}>
				Payer
			</Button>
		</form>
	);
};

export const OrderPage = () => {
	const plans = useSelector((state) => state.plan.cartItems);
	const total = useSelector((state) => state.plan.totalAmount);
	const totalduration = useSelector((state) => state.plan.totalDuration);
	const totalQuantity = useSelector((state) => state.plan.totalQuantity);
	const user = AuthStorage.isAuth();

	return (
		<Grid container style={{ margin: '5% 1% 0% 1%' }} spacing={'2'}>
			<Grid item xs={12}>
				<Typography
					variant='h4'
					sx={{ margin: '5% 0 2% 0 ', width: '100%', textAlign: 'center' }}>
					Validation de la commande
				</Typography>
			</Grid>
			<Container>
				<Grid item>
					<Typography variant='h4'>Votre commande</Typography>
					<Paper>
						<Grid
							container
							justifyContent='space-between'
							alignItems='center'
							padding={2}>
							<Grid item>
								<Typography variant='h5'>Durée</Typography>
							</Grid>
							<Grid item>
								<Typography variant='h5'>Titre</Typography>
							</Grid>
							<Grid item>
								<Typography variant='h5'>Quantité</Typography>
							</Grid>
							<Grid item>
								<Typography variant='h5'>Prix</Typography>
							</Grid>
						</Grid>
						{plans.map((plan, index) => (
							<Grid
								container
								justifyContent='space-between'
								alignItems='center'
								padding={2}
								key={index}>
								<Grid item>
									<Typography variant=''>{plan.duration}</Typography>
								</Grid>
								<Grid item>
									<Typography variant=''>{plan.title}</Typography>
								</Grid>
								<Grid item>
									<Typography variant=''>{plan.quantity}</Typography>
								</Grid>
								<Grid item>
									<Typography variant=''>{plan.price} €</Typography>
								</Grid>
							</Grid>
						))}
						{/* Sous-total */}
						<Grid container justifyContent='flex-end' padding={2}>
							<Grid item sx={{ marginRight: '5px' }}>
								<Typography variant='body1'>Total</Typography>
							</Grid>
							<Grid item>
								<Typography variant='body1'>{total} €</Typography>
							</Grid>
						</Grid>

						{/* Total */}
					</Paper>
				</Grid>
				<Grid item>
					<Typography variant='h4'>Moyens de paiement</Typography>

					<Typography variant='p' color='primary'>
						Carte de paiement (Stripe)
					</Typography>
					<Elements stripe={stripePromise}>
						<CheckoutForm
							total={total}
							user={user}
							plans={plans}
							totalQuantity={totalQuantity}
							totalduration={totalduration}
						/>
					</Elements>
				</Grid>
			</Container>
		</Grid>
	);
};
