import { useDispatch } from 'react-redux';
import { login, register } from '../api/auth';
import { AuthStorage } from '../utils/AuthStorage';
import { setLoading } from '../features/useLoading';

export const useAuthService = () => {
	const log = async (logArg, isLogin, callback) => {
		const response = isLogin
			? await login(logArg)
					.then((response) => {
						if (response.data) {
							AuthStorage.authenticate(response.data, callback);
						}
						callback(response.data);
					})
					.catch((error) => callback(error))
			: await register(logArg);
	};
	const regis = async (logArg, callback) => {
		return register(logArg);
		// .then((response) => {
		// 	callback(response);
		// })
		// .catch((error) => {
		// 	callback(error);
		// });
	};
	return {
		log,
		regis,
	};
};
