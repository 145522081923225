import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
	Typography,
	Container,
	Grid,
	styled,
	Button,
	Box,
} from '@mui/material';
import theme from '../../../theme';
import { backgrounds, homepageImage } from '../../../constants/staticImages';

const StyledContainer = styled(Container)(({ theme }) => ({
	backgroundSize: 'cover',
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'center',
	position: 'relative',
	padding: '0 !important',
}));

const StyledButton = styled(Button)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	backgroundColor: theme.palette.primary.info,
	color: '#FFF',
	fontSize: theme.typography.subtitle1.fontSize,
	borderRadius: '10px',
}));

const StyledBoxImage = styled(Box)(() => ({
	maxHeight: 75,
	width: '100%',
	left: 0,
	top: 0,
	padding: 1,
	transform: 'rotate(5deg)',
}));

const StyledBackgroundGrid = styled(Grid)(({ theme }) => ({
	height: '100%',
	width: '100%',
	position: 'absolute',
	direction: 'rtl',
	left: 0,
	top: 0,
}));
export const MapimActionType = ({ page }) => {
	const currentPage = page;
	const navigate = useNavigate();
	const handleNavigate = (value) => {
		navigate(value);
	};

	return (
		<StyledContainer
			maxWidth={false}
			sx={{
				p: 0,
				marginTop: 2,
				display: 'block',
			}}>
			<Grid
				item
				xs={12}
				position={'relative'}
				sx={{
					width: { md: '60%', xs: '100%' },
					margin: { md: 'auto' },
					textAlign: { md: 'center' },
				}}
				padding={3}>
				<Typography
					component={'div'}
					margin={5}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						gap: '20px',
					}}>
					<div style={{ textAlign: 'right', width: '90%' }}>
						{currentPage.object.title.split(' ').map((title, index) => {
							return (
								<Typography
									variant='span'
									sx={{
										fontSize: '50px',
										textTransform: 'uppercase',
										fontWeight: 'bold',
									}}
									key={index}>
									<Typography
										sx={{ color: `${theme.palette.primary.green} !important` }}
										variant='span'>
										{title[0]}
									</Typography>
									<Typography
										sx={{ color: `${theme.palette.primary.dark} !important` }}
										variant='span'>
										{title.replace(/^./, '') + ' '}
									</Typography>
								</Typography>
							);
						})}
					</div>
					<div>
						{currentPage.icon && (
							<StyledBoxImage
								component={'img'}
								sx={{ transform: 'rotate(20deg)' }}
								src={
									currentPage.remoteIcon
										? homepageImage(currentPage.icon)
										: currentPage.icon
								}
							/>
						)}
					</div>
				</Typography>

				<Typography
					variant='h5'
					margin={5}
					sx={{ textAlign: { xs: 'center' } }}>
					{currentPage.object.description1.sentences}
				</Typography>

				<Grid
					item
					xs={12}
					sx={{
						marginTop: 5,
						marginBottom: 2,
						p: 2,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}>
					<Button
						onClick={() => handleNavigate(currentPage.buttonLink)}
						sx={{
							paddingX: '100px',
							paddingY: '15px',
							borderRadius: '50px',
							color: 'white',
							textTransform: 'none',
							fontWeight: 'bold',
							backgroundColor: '7eb9a3 !important',
						}}
						size='large'
						variant='contained'>
						{currentPage.buttonText}
					</Button>
				</Grid>

				<Typography
					variant='h5'
					margin={5}
					sx={{ textAlign: { xs: 'center' } }}>
					<Box>
						{currentPage.object.description2.sentences}
						<br />
					</Box>
				</Typography>
			</Grid>
		</StyledContainer>
	);
};
