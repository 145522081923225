import React from 'react';
import { Box, Stack } from '@mui/material';
import {
	Person,
	Speed,
	MenuBook,
	QuestionAnswer,
	Close,
} from '@material-ui/icons';
import { Badge, Quiz, FileCopyRounded } from '@mui/icons-material';
import Drawer from '@mui/material/Drawer';

import SideButton from './SideButton';
import IconButton from '@mui/material/IconButton';
import { useLocation, useParams } from 'react-router-dom';

export const SIDEBAR_SIZE = 256;

export const DashboardSidebar = ({ open, setOpen, top, customContent }) => {
	const url = useParams();
	const { pathname } = useLocation();
	const numberOfParams = url['*'].split('/');

	return (
		((!pathname.includes('/dashboard/courses') ||
			numberOfParams.length !== 3) && (
			<Drawer
				sx={{
					width: SIDEBAR_SIZE,
					flexShrink: 0,
					'& .MuiDrawer-paper': {
						width: SIDEBAR_SIZE,
						boxSizing: 'border-box',
						padding: customContent ? '' : '38px 0 0 38px',
						borderRight: '2px solid black',
						top,
					},
				}}
				variant='persistent'
				anchor='left'
				open={!!customContent || open}>
				{customContent || (
					<>
						<IconButton
							sx={{ position: 'absolute', top: 0, left: 0 }}
							onClick={() => setOpen(false)}>
							<Close />
						</IconButton>
						<Stack spacing={1} direction='column'>
							<SideButton label='Tableau de bord' icon={<Speed />} route='' />
							<SideButton label='Profil' icon={<Person />} route='/profile' />
							<SideButton
								label='Mes cours'
								icon={<MenuBook />}
								route='/courses'
							/>
							<SideButton label='Q&R' icon={<QuestionAnswer />} route='/faq' />
							<SideButton label='Quiz' icon={<Quiz />} route='/quiz' />
							<SideButton
								label='Attestations'
								icon={<Badge />}
								route='/certificates'
							/>
							<SideButton
								label='Factures'
								icon={<FileCopyRounded />}
								route='/factures'
							/>
						</Stack>
					</>
				)}
			</Drawer>
		)) ||
		(pathname.includes('/dashboard/courses') && numberOfParams.length === 3 && (
			<Drawer
				variant='persistent'
				anchor='left'
				sx={{
					width: SIDEBAR_SIZE,
					flexShrink: 0,
					'& .MuiDrawer-paper': {
						width: SIDEBAR_SIZE,
						boxSizing: 'border-box',
						padding: customContent ? '' : '38px 0 0 38px',
						borderRight: '2px solid black',
						top,
					},
				}}
				open={!!customContent || open}>
				{customContent}
			</Drawer>
		))
	);
};
