import React from "react";
import { Button, styled, Typography } from "@mui/material";

import { PRIMARY } from "../../../theme/utils/color";
import { useLocation, useNavigate } from "react-router-dom";

const SideButtonStyled = styled(Button)(({ theme }) => ({
    // Reset CSS for this button
    textTransform: 'none',

    // Custom CSS
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
    gap: "10px",
    padding: "5px 15px",
    borderRadius: "10px 0 0 10px",
    color: "#000",
    '&:hover': {
        backgroundColor: PRIMARY.green,
        color: `#fff`
    },
}));

export default function SideButton ({ icon, label, route }) {
    const location = useLocation();
    const navigate = useNavigate();

    const isActive = (pathname) => {
        if (!pathname) return location.pathname === '/dashboard' ? 'dashboard-sidebar-focused' : '';
        return location.pathname.startsWith('/dashboard' + pathname) ? 'dashboard-sidebar-focused' : '';
    };

    return (
        <SideButtonStyled className={isActive(route)} onClick={() => navigate('/dashboard' + route)}>
            {icon}
            <Typography sx={{ fontWeight: "600" }}>{label}</Typography>
        </SideButtonStyled>
    );
}