import React, { useEffect } from 'react';
import {
	Container,
	styled,
	Grid,
	Paper,
	Button,
	Typography,
} from '@mui/material';
import { REGISTER } from '../../constants';
import { CustomForm } from '../../components/form';
import { useNavigate } from 'react-router-dom';
import { useAuthService } from '../../hook/useAuthService';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../features/useLoading';
import { toast } from 'react-toastify';

const StyledContainer = styled(Container)(() => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	color: '#FFF',
	minHeight: '100vh',
	width: 'inherit !important',
	maxWidth: '100% !important',
	background: `url(/auth/auth-bg.webp)`,
	backgroundSize: 'cover',
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'center',
}));

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor:
		theme.palette.mode === 'dark' ? '#1A2027' : 'rgba(255,255,255, 0.75)',
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: 'center',
	color: theme.palette.text.secondary,
	overflow: 'auto',
}));

const StyledButton = styled(Button)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	backgroundColor: theme.palette.primary.main,
	color: '#FFF',
	fontSize: theme.typography.subtitle1.fontSize,
	borderRadius: '50px',
	margin: '5px',
	padding: '15px',
}));

export const Register = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		setTimeout(() => dispatch(setLoading(false)), 1500);
	}, []);
	const { log, regis } = useAuthService();

	const history = useNavigate();
	const handleRoute = () => {
		history('/auth/login');
	};
	const handleInsert = async (event) => {
		dispatch(setLoading(true));
		regis(event)
			.then(() => {
				toast.success(
					'Vous êtes inscrits sur Mapimmo, Vous allez être redirigé vers la page de connexion maintenant',
					{
						position: 'center',
						autoClose: 5000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: 'light',
					}
				);
				setTimeout(() => (window.location.href = '/auth/login'), 2000);
			})
			.catch((e) => {
				toast.success(
					"Il y a eu une erreur, veuillez contacter l'administrateur",
					{
						position: 'top-right',
						autoClose: 5000,
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: 'light',
					}
				);
			})
			.finally(() => dispatch(setLoading(false)));
	};
	return (
		<StyledContainer>
			<Grid
				container
				justifyContent={`center`}
				alignItems={`center`}
				spacing={2}>
				<Grid item xs={12} md={6}>
					<Item sx={{ boxShadow: '15px 15px 20px grey', padding: '20px' }}>
						{/* <Grid container justifyContent="center" alignItems="center" >
                            <Avatar src={images('logo.png')} style={{width: "250px", height: "250px", marginTop: '-50px'}} />
                        </Grid> */}
						<CustomForm
							inputList={REGISTER}
							submitForm={handleInsert}
							submitText="S'inscrire"
						/>
						<Typography textAlign={`center`} variant='subtitle1' sx={{ my: 0 }}>
							Déjà un compte ?
						</Typography>
						<Grid container justifyContent='center' alignItems='center'>
							<StyledButton
								sx={{ p: 1 }}
								onClick={handleRoute}
								variant='outlined'
								size='small'>
								Se connecter
							</StyledButton>
						</Grid>
					</Item>
				</Grid>
			</Grid>
		</StyledContainer>
	);
};
