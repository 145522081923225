import { createHttp } from '../api/http';

const http = createHttp();

export const useFormation = {
	fetchAllFormation: async () => await http.get('/formation'),

	getByIdFormation: async (id) => await http.get(`/formation/${id}`),
	getByUuidFormation: async (id) => await http.get(`/formation/uuid/${id}`),
	fetchFormation: async (uuid) => await http.get('/formation/' + uuid),
	startFormation: async (uuid) =>
		await http.post(`/formation/${uuid}/take-course`),

	makeFormationDone: async (uuid, lessonId, isDone = false) =>
		await http.post(`/formation/${uuid}/make-done`, {
			lesson_done: lessonId,
			is_done: isDone,
		}),
};
