import React, { useEffect, useMemo } from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
} from '@mui/material';
import {
	removePlanCart,
	incrementPlantCart,
	decrementPlanCart,
} from '../../features/plan/usePlan';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Tooltip from '@material-ui/core/Tooltip';
import { setLoading } from '../../features/useLoading';
import { AuthStorage } from '../../utils/AuthStorage';

export const CartPage = () => {
	const total = useSelector((state) => state.plan.totalAmount);
	const totalduration = useSelector((state) => state.plan.totalDuration);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	setTimeout(() => {
		dispatch(setLoading(false));
	}, 1500);
	const handleRemoveCart = (planId) => {
		dispatch(removePlanCart(planId));
	};

	const handleIncrementCart = (planId) => {
		dispatch(incrementPlantCart(planId));
	};

	const handleDecrementCart = (planId) => {
		dispatch(decrementPlanCart(planId));
	};
	const user = AuthStorage.isAuth();
	const handleOrder = () => {
		if (user) {
			navigate('/commande');
		} else return navigate('/auth/login');
	};
	const plans = useSelector((state) => state.plan.cartItems);

	return (
		<>
			<Box sx={{ width: '100%', marginTop: '100px' }}>
				<Box
					sx={{
						display: 'flex',
						flexWrap: 'auto',
						alignContent: 'center',
						justifyContent: 'center',
						gap: '30px',
					}}>
					<Typography variant='h4'>Panier</Typography>
				</Box>
			</Box>
			<Container>
				<TableContainer component={Paper}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>Quantité</TableCell>
								<TableCell>Produit</TableCell>
								<TableCell>Prix</TableCell>
								<TableCell>Durée</TableCell>
								<TableCell></TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{plans.map((plan, index) => (
								<TableRow key={index}>
									<TableCell>
										<Tooltip title='Plus'>
											<Button onClick={() => handleIncrementCart(plan.id)}>
												<AddIcon />
											</Button>
										</Tooltip>
										{plan.quantity}
										<Tooltip title='Moins'>
											<Button onClick={() => handleDecrementCart(plan.id)}>
												<RemoveIcon />
											</Button>
										</Tooltip>
									</TableCell>
									<TableCell>{plan.title}</TableCell>
									<TableCell>{plan.price}</TableCell>

									<TableCell>{plan.duration}</TableCell>
									<TableCell>
										<Button
											variant='contained'
											onClick={() => {
												handleRemoveCart(plan.plan_id);
											}}>
											Supprimer
										</Button>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Container>
			<div style={{ width: '50%', margin: '3% 25% 0 25%' }}>
				<Container>
					<Typography variant='h5'>Total dans le panier</Typography>
					<TableContainer component={Paper}>
						<Table>
							<TableBody>
								<TableRow>
									<TableCell>Total</TableCell>
									<TableCell>{total} €</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>Durée total</TableCell>
									<TableCell>{totalduration}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>
										<Button
											onClick={() => handleOrder()}
											disabled={!total}
											variant='contained'>
											Valider la commande
										</Button>
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
				</Container>
			</div>
		</>
	);
};
