import React from "react";
import { Route, Routes } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';

import { Login } from '../../pages/authentication/Login';
import { ForgotPassword } from '../../pages/authentication/ForgotPassword';
import { Register } from '../../pages/authentication/Register';

export const AuthenticationRoute = () => {
    return (
        <HelmetProvider>
            <Routes>
                <Route path="/login" element={<Login/>}/>
                <Route path="/forgetpassword" element={<ForgotPassword/>}/>
                <Route path="/register" element={<Register/>}/>
            </Routes>
        </HelmetProvider>
    );
}