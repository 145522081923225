import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import {
	AccessTime,
	Computer,
	FileCopy,
	Home,
	MenuBook,
} from '@material-ui/icons';
import { PRIMARY } from '../../theme/utils/color';
import { useNavigate } from 'react-router-dom';
import { Badge, Quiz, FileCopyRounded } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { fetchAllFormations } from '../../features/formations/useFormation';
import { createHttp } from '../../api/http';
import { setLoading } from '../../features/useLoading';
import { useQuizz } from '../../services/useQuizz';
import { useOrder } from '../../services/useOrder';
import { AuthStorage } from '../../utils/AuthStorage';

function Card({ name, icon, count, route }) {
	const navigate = useNavigate();
	return (
		<Box
			sx={{
				border: '1px solid black',
				borderRadius: 5,
				padding: '24px 0',
				display: 'flex',
				justifyContent: 'center',
				flexDirection: 'column',
				textAlign: 'center',
				alignItems: 'center',
				width: 256,
				maxWidth: '80%',
				transition: 'all .5s ease',
				zIndex: 99,
				cursor: route ? 'pointer' : 'unset',
				'&:hover': {
					border: '1px solid ' + PRIMARY.green,
					transform: 'translateY(-5px)',
				},
			}}
			onClick={() => (route ? navigate('/dashboard' + route) : null)}>
			{icon}
			<Typography sx={{ marginTop: '16px' }} variant='h3'>
				{count}
			</Typography>
			<Typography variant='h6'>{name}</Typography>
		</Box>
	);
}

export const BoardPage = () => {
	const storeFormations = useSelector((state) => state.formation.formations);
	const currentUser = AuthStorage.isAuth().user;
	const [myQuizz, setMyQuizz] = useState();
	const [invoice, setInvoice] = useState();
	const [attestations, setAttestations] = useState([]);
	const dispatch = useDispatch();
	const http = createHttp();
	const [user, setUser] = useState(null);
	useEffect(() => {
		if (!user) {
			http.get('/user/' + currentUser.id).then((res) => {
				setUser(res.data);
			});
		}
		if (!invoice)
			useOrder.fetchAllOrders().then((data) => {
				setInvoice(data.data);
			});

		if (!myQuizz) getMyQuizz();

		http.get('/attestation').then((response) => {
			setAttestations(response.data);
			setTimeout(() => {
				dispatch(setLoading(false));
			}, 1500);
		});
	}, []);

	/**
	 * Format number to have 00 or 0 on start
	 */
	const formatNumberToDate = (numberToFormat) => {
		let formatedNumber = `${numberToFormat}`;
		if (numberToFormat < 10) formatedNumber = `0${numberToFormat}`;
		return formatedNumber;
	};

	const getValidatedHours = (attestations) => {
		if (attestations.length !== 0) {
			let validated = '00:00:00';
			attestations.forEach((attestation) => {
				validated = addDate(validated, attestation.hour_done);
			});

			return validated;
		}
		return soustraireTemps(user.hour_remains, user.available_hour);
	};

	// Fonction pour convertir une chaîne de temps en objet de date
	function convertirEnDate(heureString) {
		if (heureString) {
			var parties = heureString.split(':');
			return new Date(0, 0, 0, parties[0], parties[1], parties[2]);
		}
		return new Date(0, 0, 0, 0, 0, 0);
	}

	// Fonction pour soustraire deux chaînes de temps
	function soustraireTemps(heure1, heure2) {
		var date1 = convertirEnDate(heure1);
		var date2 = convertirEnDate(heure2);

		// Soustraire les dates pour obtenir la différence en millisecondes
		var difference = date1 - date2;

		// Calculer les composants d'heure, de minute et de seconde
		var heures = Math.floor(difference / 3600000);
		var minutes = Math.floor((difference % 3600000) / 60000);
		var secondes = Math.floor((difference % 60000) / 1000);

		// Retourner la différence sous forme de chaîne "HH:mm:ss"
		return (
			(heures < 10 ? '0' : '') +
			heures +
			':' +
			(minutes < 10 ? '0' : '') +
			minutes +
			':' +
			(secondes < 10 ? '0' : '') +
			secondes
		);
	}

	const addDate = (date1, date2) => {
		// Your time duration string

		// Parse the time duration string
		let [hour1, minutes1, seconds1] = date1.split(':').map(Number);
		let [hour2, minutes2, seconds2] = date2.split(':').map(Number);

		return [
			hour1 + hour2,
			formatNumberToDate(minutes1 + minutes2),
			formatNumberToDate(seconds1 + seconds2),
		].join(':');
	};

	const getMyQuizz = async () => {
		const quizzByFormation = [];
		useQuizz.myQuizz().then(({ data }) => {
			data.forEach((quizz) => {
				const formation = quizz.question.formation;
				if (!quizzByFormation[formation.id]) {
					quizzByFormation[formation.id] = [];
					quizzByFormation[formation.id].push(quizz);
				} else {
					quizzByFormation[formation.id].push(quizz);
				}
			});
			const quizzByFormations = quizzByFormation.filter(
				(quizz) => quizz != null
			);
			setMyQuizz(quizzByFormations);
		});
	};

	return (
		storeFormations &&
		user &&
		myQuizz && (
			<Box sx={{ maxWidth: '100%', padding: '32px 0 32px 48px' }}>
				<Typography sx={{ marginBottom: '30px', zIndex: 0 }} variant={'h4'}>
					Mon tableau de bord
				</Typography>
				<Box style={{ display: 'flex', flexWrap: 'wrap', gap: 32, padding: 5 }}>
					<Card
						name='Cours suivis'
						count={
							storeFormations?.filter((formation) => !formation.is_done).length
						}
						icon={
							<MenuBook
								style={{
									color: '#fff',
									backgroundColor: PRIMARY.green,
									padding: 10,
									borderRadius: '50%',
									fontSize: 54,
								}}
							/>
						}
						route='/courses/'
					/>
					<Card
						name='Cours terminés'
						count={
							storeFormations?.filter((formation) => formation.is_done).length
						}
						icon={
							<Computer
								style={{
									color: '#fff',
									backgroundColor: PRIMARY.green,
									padding: 10,
									borderRadius: '50%',
									fontSize: 54,
								}}
							/>
						}
						route='/courses'
					/>
					<Card
						name='Quiz effectués'
						count={myQuizz.length}
						icon={
							<Quiz
								style={{
									color: '#fff',
									backgroundColor: PRIMARY.green,
									padding: 10,
									borderRadius: '50%',
									fontSize: 54,
								}}
							/>
						}
						route='/quiz'
					/>
					<Card
						name='Mes heures validées'
						count={getValidatedHours(attestations)}
						icon={
							<AccessTime
								style={{
									color: '#fff',
									backgroundColor: PRIMARY.green,
									padding: 10,
									borderRadius: '50%',
									fontSize: 54,
								}}
							/>
						}
					/>
					<Card
						name='Mes heures restantes'
						count={user.available_hour}
						icon={
							<AccessTime
								style={{
									color: '#fff',
									backgroundColor: PRIMARY.green,
									padding: 10,
									borderRadius: '50%',
									fontSize: 54,
								}}
							/>
						}
					/>
					<Card
						name='Mes attestations'
						count={attestations.length}
						icon={
							<Badge
								style={{
									color: '#fff',
									backgroundColor: PRIMARY.green,
									padding: 10,
									borderRadius: '50%',
									fontSize: 54,
								}}
							/>
						}
						route='/certificates'
					/>
					<Card
						name='Mes Factures'
						count={invoice?.length}
						icon={
							<FileCopyRounded
								style={{
									color: '#fff',
									backgroundColor: PRIMARY.green,
									padding: 10,
									borderRadius: '50%',
									fontSize: 54,
								}}
							/>
						}
						route='/factures'
					/>
				</Box>
			</Box>
		)
	);
};
