import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Replay } from '@material-ui/icons';

export default function FilterDialog({
	open,
	handleClose,
	theme,
	handleDurationChange,
	removeFilter,
	handleThemeChange,
	hourFilter,
	filterTheme,
	filterDuration,
}) {
	return (
		<>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'>
				<DialogTitle id='alert-dialog-title'>
					{'Filtrer les modules disponible'}
				</DialogTitle>
				<DialogContent>
					<div style={{ border: '1px solid grey', padding: '20px' }}>
						<Button
							component='label'
							variant='contained'
							color='secondary'
							onClick={() => removeFilter()}>
							<Replay />
							Réinitialiser
						</Button>

						<div className='hour-filter'>
							<h4>Nombre d'heures :</h4>
							{hourFilter.map((hour, index) => {
								return (
									<div key={index}>
										<input
											type='radio'
											checked={hour == filterDuration}
											name='hour'
											onChange={(e) => handleDurationChange(e.target.value)}
											value={hour}
										/>
										<label>{hour}h</label>
									</div>
								);
							})}
							<div>
								<label>Préciser le nombre exacte</label>
								<input
									value={
										hourFilter.indexOf(filterDuration) === -1 &&
										filterDuration !== undefined
									}
									onChange={() => {}}
									type='number'
									onKeyDown={(e) => {
										if (e.keyCode === 13) handleDurationChange(e.target.value);
									}}
									style={{ border: 'none', borderBottom: '1px dashed black' }}
								/>
							</div>
						</div>
						<div className='theme-filter'>
							<h4>Thème :</h4>
							{theme.map((thematique, index) => (
								<div key={index}>
									<input
										type='checkbox'
										name='label'
										checked={filterTheme.indexOf(thematique.label) !== -1}
										value={thematique.label}
										onChange={(e) => handleThemeChange(e.target)}
									/>
									<label>{thematique.label}</label>
								</div>
							))}
						</div>
					</div>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Filtrer</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}
