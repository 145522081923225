import { createSlice } from '@reduxjs/toolkit';

const items = JSON.parse(localStorage.getItem('cartItems')) || [];

const totalAmount = JSON.parse(localStorage.getItem('totalAmount')) || 0;

const totalQuantity = JSON.parse(localStorage.getItem('totalQuantity')) || 0;

const totalDuration = JSON.parse(localStorage.getItem('totalDuration')) || 0;

const setItemFunc = (item, totalAmount, totalQuantity, totalDuration) => {
	localStorage.setItem('cartItems', JSON.stringify(item));
	localStorage.setItem('totalAmount', JSON.stringify(totalAmount));
	localStorage.setItem('totalQuantity', JSON.stringify(totalQuantity));
	localStorage.setItem('totalDuration', JSON.stringify(totalDuration));
};

const calculateTotalDuration = (cartItems) => {
	let totalDurationInSeconds = 0;
	for (const item of cartItems) {
		const [hours, minutes, seconds] = item.duration.split(':');
		const durationInSeconds =
			parseInt(hours, 10) * 3600 +
			parseInt(minutes, 10) * 60 +
			parseInt(seconds, 10);
		const durationWithQuantityInSeconds = durationInSeconds * item.quantity;
		totalDurationInSeconds += durationWithQuantityInSeconds;
	}

	const totalHours = String(Math.floor(totalDurationInSeconds / 3600)).padStart(
		2,
		'0'
	);
	const totalMinutes = String(
		Math.floor((totalDurationInSeconds % 3600) / 60)
	).padStart(2, '0');
	const totalSeconds = String(totalDurationInSeconds % 60).padStart(2, '0');
	return `${totalHours}:${totalMinutes}:${totalSeconds}`;
};

const initialState = {
	cartItems: items,
	totalQuantity: totalQuantity,
	totalAmount: totalAmount,
	totalDuration: totalDuration,
};

export const usePlan = createSlice({
	name: 'plan',
	initialState,
	reducers: {
		addPlanCart: (state, action) => {
			const newItem = action.payload;
			const id = action.payload.id;
			const existingItem = state.cartItems.find((item) => item.plan_id === id);

			if (!existingItem) {
				if (newItem.sale_price != null) {
					state.cartItems.push({
						plan_id: newItem.id,
						title: newItem.title,
						duration: newItem.duration,
						price: newItem.sale_price,
						quantity: 1,
						totalPrice: newItem.price,
					});
				} else {
					state.cartItems.push({
						plan_id: newItem.id,
						title: newItem.title,
						duration: newItem.duration,
						price: newItem.price,
						quantity: 1,
						totalPrice: newItem.price,
					});
				}
				state.totalQuantity++;
			} else {
				existingItem.quantity++;

				existingItem.totalPrice =
					Number(existingItem.totalPrice) + Number(existingItem.price);
			}
			state.totalAmount = state.cartItems.reduce(
				(total, item) => total + Number(item.totalPrice),
				0
			);
			state.totalQuantity = state.cartItems.reduce(
				(total, item) => total + (item.quantity > 0 ? item.quantity : 0),
				0
			);
			state.totalDuration = calculateTotalDuration(state.cartItems);

			setItemFunc(
				state.cartItems.map((item) => item),
				state.totalAmount,
				state.totalQuantity,
				state.totalDuration
			);
		},

		decrementPlanCart(state, action) {
			const id = action.payload;
			const existingItem = state.cartItems.find((item) => item.plan_id === id);
			if (existingItem) {
				if (existingItem.quantity === 1) {
					state.cartItems = state.cartItems.filter(
						(item) => item.plan_id !== id
					);
				} else {
					existingItem.quantity--;
					existingItem.totalPrice =
						Number(existingItem.totalPrice) - Number(existingItem.price);
				}
				state.totalQuantity--;
			}
			state.totalAmount = state.cartItems.reduce(
				(total, item) => total + Number(item.totalPrice),
				0
			);
			state.totalQuantity = state.cartItems.reduce(
				(total, item) => total + (item.quantity > 0 ? item.quantity : 0),
				0
			);
			state.totalDuration = calculateTotalDuration(state.cartItems);
			setItemFunc(
				state.cartItems.map((item) => item),
				state.totalAmount,
				state.totalQuantity,
				state.totalDuration
			);
		},

		removePlanCart(state, action) {
			const id = action.payload;
			const existingItem = state.cartItems.find((item) => item.plan_id === id);
			if (existingItem) {
				state.cartItems = state.cartItems.filter((item) => item.plan_id !== id);
				state.totalQuantity -= existingItem.quantity;
			}

			state.totalAmount = state.cartItems.reduce(
				(total, item) => total + Number(item.totalPrice),
				0
			);
			state.totalDuration = calculateTotalDuration(state.cartItems);
			setItemFunc(
				state.cartItems.map((item) => item),
				state.totalAmount,
				state.totalQuantity,
				state.totalDuration
			);
		},

		incrementPlantCart(state, action) {
			const id = action.payload;
			const existingItem = state.cartItems.find((item) => item.plan_id === id);

			if (existingItem) {
				existingItem.quantity++;
				existingItem.totalPrice =
					Number(existingItem.totalPrice) + Number(existingItem.price);

				state.totalAmount = state.cartItems.reduce(
					(total, item) => total + Number(item.totalPrice),
					0
				);
			}
			state.totalQuantity = state.cartItems.reduce(
				(total, item) => total + (item.quantity > 0 ? item.quantity : 0),
				0
			);
			state.totalDuration = calculateTotalDuration(state.cartItems);
			setItemFunc(
				state.cartItems.map((item) => item),
				state.totalAmount,
				state.totalQuantity,
				state.totalDuration
			);
		},
	},
});

export const {
	addPlanCart,
	removePlanCart,
	incrementPlantCart,
	decrementPlanCart,
} = usePlan.actions;

export default usePlan.reducer;
