import React, { useContext } from "react";
import { Typography, styled, Grid} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ActivemenuContext } from "../../../contexts/modules/activemenu.context";
import './menu.css'

const StyledGrid = styled(Grid)(({ theme }) => ({
    height: "100px",
    width: "150px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: theme.palette.primary.main,
    color: "#FFF",
    borderRadius: "5px",
    margin: "1px"
  }));

export const MenuGrid = ({menu}) => {
    const navigate = useNavigate()
    const {active, setActive} = useContext(ActivemenuContext)
    const handleNavigate = async (link) => {
        try {
            setActive(link)
            navigate(link)
        } catch (error) {
            console.log(error);
        }
    }
    return (
        <StyledGrid
            item
            onClick={() => handleNavigate(menu.link)}
            className={`menu-item ${active === menu.link && 'active'}`}
        >
        
            <Typography align="center" variant="subtitle1" sx={{ mb: 1 }}>
                {menu.label}
            </Typography>
        </StyledGrid>
            
    )
}