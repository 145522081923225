import React, { useLayoutEffect } from 'react';
import { Container, styled, Grid, Box } from '@mui/material';
import { backgrounds } from '../../../constants/staticImages';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all';

const StyledContainer = styled(Container)(() => ({
	maxWidth: 'inherit !important',
	opacity: 0.2,
	zIndex: -11,
	position: 'absolute',
	top: 0,
	left: 0,
	display: 'grid',
	minHeight: '100%',
}));

const StyledBackgroundGrid = styled(Grid)(() => ({
	left: 0,
	top: 0,
	marginTop: '5%',
	display: 'block',
}));

const separatedLogo = {
	logo_O: 'LOGO_MAPIM_IMMO_SI_NOIR_copie1.png',
	logo_m: 'LOGO_MAPIM_IMMO_SI_NOIR_copie.png',
	logo_ma: 'LOGO_MAPIM_IMMO_SI_NOIR_copie2.png',
	logo_map: 'LOGO_MAPIM_IMMO_SI_NOIR_copie3.png',
	logo_mapi: 'LOGO_MAPIM_IMMO_SI_NOIR_copie4.png',
	logo_mapim: 'LOGO_MAPIM_IMMO_SI_NOIR_copie5.png',
	logo_mapimimmo: 'LOGO_MAPIM_IMMO_SI_NOIR_copie1.png',
};
export const Background = () => {
	gsap.registerPlugin(ScrollTrigger);
	useLayoutEffect(() => {
		const tl = gsap.timeline({
			repeat: -1,
		});
		Object.keys(separatedLogo).forEach((logo, index) => {
			tl.to('#separated-logo-' + index, {
				opacity: 1,
				x: 100 * index,
				duration: 2,
				rotateY: 360,
				scrollTrigger: {
					trigger: '#separated-logo-' + index,
					scrub: true,
					toggleActions: 'restart restart restart restart',
				},
			});
		});
	});

	return (
		<StyledContainer sx={{ px: 0 }}>
			<StyledBackgroundGrid container spacing={2}>
				{Object.keys(separatedLogo).map((element, idx) => (
					<Grid
						xs={12}
						alignSelf={'end'}
						id={'separated-logo-' + idx}
						key={idx}
						sx={{
							maxHeight: `${100}%`,
							position: 'relative',
							display: 'flex',
							justifyContent:
								((idx === 4 || idx === 0) && 'center') ||
								(parseInt(idx) % 2 === 0 && 'end') ||
								'start',
							height: `${100 / Object.keys(separatedLogo).length}%`,
						}}
						item>
						<Box
							component={'img'}
							id={Object.keys(separatedLogo)[idx]}
							src={backgrounds(separatedLogo[element])}
							sx={{
								maxHeight: '100%',
								maxWidth: '35%',
								objectFit: 'contain',
							}}></Box>
					</Grid>
				))}
			</StyledBackgroundGrid>
		</StyledContainer>
	);
};
