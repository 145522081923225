import { createSlice } from '@reduxjs/toolkit';
// import { createHttp } from '../../api/http';
// const http = createHttp();

export const useFormation = createSlice({
	name: 'formation',
	initialState: {
		formations: [],
	},
	reducers: {
		fetchAllFormations: (state, actions) => {
			// const res = await http.get('/dashboard/my-plateforme');
			// console.log(typeof res.data);
			// state.formations = res.data;
			state.formations = actions.payload;
		},
	},
})

export const { fetchAllFormations } = useFormation.actions;
export default useFormation.reducer;
