import { createHttp } from './http';

const http = createHttp();

const login = async ({ email, password }) => {
  return await http.post("/auth/login", { email, password });
};

const register = async (registerInput) => {
  return await http.post("/auth/register", registerInput);
};

export { login, register };
