import React from 'react';
import { Typography } from '@mui/material';
import theme from '../../../theme';

export const StyledCenteredTitle = ({
	customText,
	normalText = null,
	variant,
	fontSize = 'initial',
}) => {
	return (
		<>
			<Typography
				variant={variant}
				fontSize={fontSize}
				sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
				<Typography
					sx={{ color: `${theme.palette.primary.green} !important` }}
					variant='span'>
					{customText}
				</Typography>
				<Typography
					sx={{ color: `${theme.palette.primary.dark} !important` }}
					variant='span'>
					{normalText}
				</Typography>
			</Typography>
		</>
	);
};
