import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { StylesProvider } from '@mui/styles';
import { MainRouter } from './router';
import { HelmetProvider } from 'react-helmet-async';
import theme from './theme';
import './App.css';
import { Box } from '@mui/system';
import { store } from './store';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
	return (
		<Provider store={store}>
			<HelmetProvider>
				<BrowserRouter>
					<ThemeProvider theme={theme}>
						<StylesProvider injectFirst>
							<CssBaseline />
							<Box sx={{ width: '100%' }}>
								<MainRouter />
								<ToastContainer />
							</Box>
						</StylesProvider>
					</ThemeProvider>
				</BrowserRouter>
			</HelmetProvider>
		</Provider>
	);
};

export default App;
