import React, { useState } from 'react';
import { Box, Button, styled, Typography } from '@mui/material';
import { CustomInput } from '../utils/modules/CustomInput';

const StyledButton = styled(Button)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	justifySelf: 'end',
	backgroundColor: theme.palette.primary.info,
	color: theme.palette.primary.light,
	fontSize: theme.typography.subtitle1.fontSize,
	borderRadius: '5px',
}));

export const CustomForm = ({ inputList, submitForm, submitText }) => {
	const [values, setValues] = useState({});
	const handleChange = (event) => {
		setValues((prev) => ({
			...prev,
			...event,
		}));
	};
	const handleSubmit = (event) => {
		event.preventDefault();
		submitForm(values);
	};

	return (
		<Box
			component='form'
			onSubmit={(e) => handleSubmit(e)}
			sx={{ px: 5, minWidth: '80%' }}>
			{inputList.map((item, index) => {
				return <CustomInput {...item} key={index} onChange={handleChange} />;
			})}
			<StyledButton sx={{ p: 1 }} type='submit' variant='outlined' size='large'>
				<Typography variant='h6'>{submitText}</Typography>
			</StyledButton>
		</Box>
	);
};
