export const LOCALSTORAGE = "mapimmo"
export const AuthStorage = {
    isAuth: () => {
      if (typeof (window) === "undefined") return undefined;
      if (localStorage.getItem(LOCALSTORAGE))
        return JSON.parse(
          localStorage.getItem(LOCALSTORAGE)
        );
      return undefined;
    },
    authenticate: (
      user,
      callback
    ) => {
      if (typeof window !== "undefined") {
        localStorage.setItem(LOCALSTORAGE, JSON.stringify(user));
        return callback && callback(user);
      }
      return undefined;
    },
    clearToken: (callback) => {
      if (typeof window !== "undefined") localStorage.removeItem(LOCALSTORAGE);
      callback && callback();
    },
  };