import React from 'react';
import { Avatar, Box, Stack, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Home, Notifications, PowerOff } from '@material-ui/icons';

import { PRIMARY } from '../../../theme/utils/color';
import { AuthStorage } from '../../../utils/AuthStorage';
import { Logout } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../features/useLoading';

export const DashboardHeader = () => {
	const dispatch = useDispatch();
	const takeFirstCharacters = (text) => text[0];
	const logout = () => {
		dispatch(setLoading(true));
		AuthStorage.clearToken();
		setTimeout(() => {
			dispatch(setLoading(true));
		}, 1000);
	};
	const user = AuthStorage.isAuth().user;
	return (
		<Box
			sx={{
				width: '100%',
				justifyContent: 'space-between',
				display: 'flex',
				padding: '30px',
				borderBottom: '2px solid black',
			}}>
			<Stack spacing={2} direction='row' alignItems='center'>
				<Avatar size='xlarge' sx={{ width: 64, height: 64 }}>
					{takeFirstCharacters(user.name)} {takeFirstCharacters(user.lastname)}
				</Avatar>
				<Typography variant={'h5'}>
					{user.name} {user.lastname}
				</Typography>
			</Stack>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					padding: '0 5px',
					cursor: 'pointer',
					borderRadius: '25px',
					gap: '20px',
					maxHeight: '35px',
				}}>
				<Home onClick={() => (window.location.href = '/')} />
				<Logout
					onClick={() => logout()}
					style={{ fontSize: '16px', color: 'orange' }}
				/>
			</div>
		</Box>
	);
};
