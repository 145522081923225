import React, { useState } from 'react';
import { Box, Grid, styled, Fab } from '@mui/material';
import { icons, staticimages } from '../../../constants/staticImages';
import AlertDialog from '../../dialog/AlertDialog';

const StyledBox = styled(Box)(() => ({
	position: 'fixed',
	right: -75,
	bottom: 0,
	zIndex: 111,
}));
const StyledBoxImage = styled(Box)(({ theme }) => ({
	maxHeight: 75,
	maxWidth: '75%',
	padding: 1,
	margin: 3,
}));
export const CustomActionGrid = ({ height, width }) => {
	const [openDialog, setOpenDialog] = useState(false);
	const closeDialog = () => setOpenDialog(false);
	return (
		<StyledBox
			style={{
				height: { xs: 'inherit', md: height },
				width: { xs: '100%', md: width },
			}}>
			<Grid container spacing={1} justifyContent='center' alignItems='strech'>
				<Grid
					item
					xs={4}
					md={12}
					style={{ display: 'flex' }}
					padding={2}
					justifyContent='center'
					alignItems='center'>
					<Fab
						color='primary'
						aria-label='add'
						style={{
							cursor: 'pointer',
						}}>
						<StyledBoxImage
							onClick={() => setOpenDialog(true)}
							component={'img'}
							src={staticimages('bouton_contact.png')}
						/>
					</Fab>
					<AlertDialog status={openDialog} close={closeDialog} />
				</Grid>
				<Grid
					item
					xs={4}
					md={12}
					style={{ display: 'flex' }}
					padding={2}
					justifyContent='center'
					alignItems='center'>
					<Fab
						color='secondary'
						aria-label='add'
						style={{
							cursor: 'pointer',
						}}>
						<StyledBoxImage
							component={'img'}
							src={staticimages('bouton_demo.png')}
						/>
					</Fab>
				</Grid>
				<Grid
					item
					xs={4}
					md={12}
					style={{ display: 'flex' }}
					padding={2}
					justifyContent='center'
					alignItems='center'>
					<Fab
						color='primary'
						aria-label='add'
						style={{
							cursor: 'pointer',
						}}>
						<StyledBoxImage
							component={'img'}
							src={staticimages('bouton_telecharger.png')}
						/>
					</Fab>
				</Grid>
			</Grid>
		</StyledBox>
	);
};
