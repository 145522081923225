const style = {
	padding: '5px',
	backgroundColor: 'rgba(23, 114, 81 , 0.56)',
	color: 'white',
	fontSize: '14px',
	margin: '5px 5px 0px 5px',
	borderRadius: '10px',
	minHeight: '100px',
};
export const CommentaireComponent = ({ commentaires }) => {
	return (
		<>
			{commentaires
				.sort(
					(a, b) =>
						new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
				)
				.map((commentaire) => (
					<div style={style} key={commentaire.id}>
						<div>{commentaire.text}</div>
						<hr />
						<div>
							{' '}
							Auteur : 
							{commentaire.admin
								? commentaire.admin.name
								: commentaire.user.name}
						</div>
						<div>
							Date : {new Date(commentaire.created_at).toLocaleString('fr')}
						</div>
					</div>
				))}
		</>
	);
};
