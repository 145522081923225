import React from 'react';
import {
	Container,
	styled,
	Grid,
	Paper,
	Typography,
	Avatar,
	Button,
} from '@mui/material';
import { Navigate, useNavigate } from 'react-router-dom';
import { LOGIN } from '../../constants';
import { CustomForm } from '../../components/form';
import { backgrounds } from '../../constants/staticImages';
import { AuthStorage } from '../../utils/AuthStorage';
import { useAuthService } from '../../hook/useAuthService';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../features/useLoading';
import { toast } from 'react-toastify';

const StyledContainer = styled(Container)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	color: '#FFF',
	minHeight: '100vh',
	maxWidth: '100% !important',
	background: `url(/auth/auth-bg.webp)`,
	backgroundSize: 'cover',
	backgroundRepeat: 'no-repeat',
}));

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor:
		theme.palette.mode === 'dark' ? '#1A2027' : 'rgba(255,255,255, 0.75)',
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: 'center',
	color: theme.palette.text.secondary,
	maxHeight: '90vh',
	overflow: 'auto',
}));

const StyledButton = styled(Button)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	backgroundColor: theme.palette.primary.main,
	color: '#FFF',
	fontSize: theme.typography.subtitle1.fontSize,
	borderRadius: '50px',
	margin: '5px',
	padding: '15px',
}));

export const Login = () => {
	const user = AuthStorage.isAuth();
	const { log } = useAuthService();

	const dispatch = useDispatch();
	setTimeout(() => dispatch(setLoading(false)), 1500);
	const history = useNavigate();
	if (user) return (window.location.href = '/dashboard');
	const handleRoute = () => {
		history('/auth/register');
	};
	const handleInsert = async (event) => {
		dispatch(setLoading(true));

		await log(event, true, (response) => {
			dispatch(setLoading(false));

			if (!response.user) {
				toast.error('Les informations que vous avez fournit sont incorrectes', {
					position: 'top-right',
					autoClose: 5000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: 'light',
				});
				return;
			}
			window.location.href = '/dashboard';
		});
	};
	return (
		<StyledContainer>
			<Grid
				container
				justifyContent={`center`}
				alignItems={`center`}
				spacing={2}>
				<Grid item xs={12} xl={4} md={6}>
					<Item sx={{ boxShadow: '15px 15px 20px grey' }}>
						<Grid container justifyContent='center' alignItems='center'>
							<Avatar
								onClick={() => history('/')}
								src={backgrounds('logo.png')}
								style={{
									width: '125px',
									height: '125px',
									marginBottom: '25px',
								}}
							/>
						</Grid>
						<CustomForm
							inputList={LOGIN}
							submitForm={handleInsert}
							submitText='Se connecter'
						/>
						<Typography textAlign={`center`} variant='subtitle1' sx={{ my: 0 }}>
							Première fois ?
						</Typography>
						<Grid container justifyContent='center' alignItems='center'>
							<StyledButton
								sx={{ p: 1 }}
								onClick={handleRoute}
								variant='outlined'
								size='small'>
								S'inscrire
							</StyledButton>
						</Grid>
					</Item>
				</Grid>
			</Grid>
		</StyledContainer>
	);
};
