import React, { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { MapimApp, MapimAction } from '../../components/block';
import { homePageData } from '../../constants/vitrine/homepageData';
import { CustomActionGrid } from '../../components/grid';
import { Background } from '../../components/block';
import { setLoading } from '../../features/useLoading';
import { useDispatch } from 'react-redux';
import { MapimSatisfaction } from '../../components/block/modules/MapimSatisfaction';
import { MapimActionType } from '../../components/block/modules/MapimActionType';
import { MapimTestimonia } from '../../components/block/modules/MapimTestimonia';

export const HomePage = () => {
	const dispatch = useDispatch();
	const allSection = homePageData();
	setTimeout(() => {
		dispatch(setLoading(false));
	}, 2000);
	return (
		<Box sx={{ width: '100%' }}>
			<Grid container justifyContent='center' alignItems='center' spacing={0.5}>
				<CustomActionGrid width={150} height={350} />
				<Grid item xs={12} display={'block'} position={'relative'}>
					<Background />
					{allSection &&
						allSection.map((section, index) => {
							if (section.type === 'information')
								return <MapimApp key={index} page={section} />;
							else if (section.type === 'action')
								return <MapimAction key={index} page={section} />;
							else if (section.type === 'client_satisfaction')
								return <MapimSatisfaction key={index} page={section} />;
							else if (section.type === 'client_testimonial')
								return <MapimTestimonia key={index} page={section} />;
							else if (section.type === 'action_type')
								return (
									<MapimActionType key={index} page={section}></MapimActionType>
								);
						})}
				</Grid>
			</Grid>
		</Box>
	);
};
