import React from "react";
import { Typography} from "@mui/material";
import theme from '../../../theme'

export const StyledTitle = ({customText, normalText = null, variant}) => {
    return (
      <>
        <Typography variant={variant} sx={{color: `${theme.palette.primary.green} !important`}}>{customText}</Typography>
        <Typography variant={variant} sx={{color: `${theme.palette.primary.dark} !important`}}>{normalText}</Typography>
      </>
    )
}
