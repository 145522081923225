import React from 'react';
import { Box, Grid, Typography, Stack } from '@mui/material';

import LinearProgressWithLabel from '../../components/utils/modules/LinearProgressWithLabel';
import { backgrounds, images } from '../../constants/staticImages';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { setLoading } from '../../features/useLoading';

export const styles = {
	rightContainer: {
		display: 'flex',
		'@media (max-width: 970px)': {
			margin: 'auto',
		},
	},
	rightContent: {
		padding: '20px 0 20px 20px',
		width: '100%',
		'@media (max-width: 970px)': {
			padding: '20px 0',
		},
	},
	text: {
		'@media (max-width: 970px)': {
			width: '100%',
			textAlign: 'center',
		},
	},
	image: {
		maxWidth: { xs: '100%' },
		width: { xs: 200 },
		objectFit: 'contain',
		height: '100%',
	},
	imageContainer: {
		'@media (max-width: 970px)': {
			margin: 'auto',
			border: 'unset',
		},
	},
};

function Course({
	totalCourses,
	coursesAchieved,
	courseName,
	courseImage,
	formation,
}) {
	const percentAchieved =
		coursesAchieved != 0
			? Math.round((coursesAchieved / totalCourses) * 100)
			: 0;

	return (
		<Grid
			container
			wrap='wrap'
			style={{
				display: 'flex',
				flexWrap: 'wrap',
				border: '2px solid black',
				borderRadius: '10px',
				maxWidth: '1080px',
				marginTop: '30px',
			}}>
			<Grid
				item
				xs='auto'
				sx={{
					borderRight: '1px solid gray',
					display: 'flex',
					alignContent: 'center',
					...styles.imageContainer,
				}}>
				<Box
					onClick={() =>
						(window.location.href = `/dashboard/courses/${formation.formation.id}/${formation.current_done}`)
					}
					component={'img'}
					sx={styles.image}
					alt='courses-image'
					src={images(courseImage)}
					padding={1}
				/>
			</Grid>
			<Grid item xs={8} sx={styles.rightContainer}>
				<Box sx={styles.rightContent}>
					<Link
						to={`/dashboard/courses/${formation.formation.id}/${formation.current_done}`}>
						<Typography sx={styles.text} variant='h4'>
							{courseName}
						</Typography>
					</Link>

					<Box
						sx={{
							display: 'flex',
							gap: '4px 32px',
							flexWrap: 'wrap',
							margin: '10px 0',
						}}>
						<Typography sx={styles.text} variant='p'>
							Total de leçons: {totalCourses}
						</Typography>
						<Typography sx={styles.text} variant='p'>
							Cours terminés: {coursesAchieved}/{totalCourses}
						</Typography>
					</Box>
					<LinearProgressWithLabel
						variant='determinate'
						value={percentAchieved}
						style={{ height: 10, borderRadius: 5 }}
						label={`${percentAchieved}% terminé`}
					/>
				</Box>
			</Grid>
		</Grid>
	);
}

export const CoursesPage = () => {
	const storeFormations = useSelector((state) => state.formation.formations);
	// const [storeFormations, setStoreFormations] = useState([]);
	const finishedFormation = storeFormations.filter(
		(formation) => formation.is_done === 1
	);
	const notFinishedFormation = storeFormations.filter(
		(formation) => formation.is_done === 0
	);
	const getTotalCours = (chapters) => {
		let numberCours = 0;
		chapters.map((chapter) => (numberCours += chapter.lessons?.length));

		return numberCours;
	};
	const dispatch = useDispatch();
	setTimeout(() => {
		dispatch(setLoading(false));
	}, 1500);
	return (
		<Box sx={{ padding: '32px 48px' }}>
			<Typography sx={{ marginBottom: '5px' }} variant={'h4'}>
				Mes cours suivis
			</Typography>
			<Stack>
				{notFinishedFormation.map((formation, index) => {
					return (
						<Course
							key={index}
							formation={formation}
							courseName={formation.formation.title}
							coursesAchieved={formation.current_done}
							totalCourses={getTotalCours(formation.formation.chapters)}
							courseImage={formation.formation.photo}
						/>
					);
				})}
			</Stack>
			<Typography sx={{ marginTop: '30px' }} variant={'h4'}>
				Mes cours terminés
			</Typography>
			<Stack>
				{finishedFormation.map((formation, index) => {
					return (
						<Course
							key={index}
							formation={formation}
							courseName={formation.formation.title}
							coursesAchieved={formation.current_done}
							totalCourses={getTotalCours(formation.formation.chapters)}
							courseImage={formation.formation.photo}
						/>
					);
				})}
			</Stack>
		</Box>
	);
};
