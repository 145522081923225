import { Grid, Typography, Box, styled } from '@mui/material';
import React from 'react';
import { StyledTitle } from '../../block/modules/StyledTilte';
import { homepageImage, icons } from '../../../constants/staticImages';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const StyledBoxImage = styled(Box)(() => ({
	maxHeight: 75,
	width: '100%',
	left: 0,
	top: 0,
	padding: 1,
	transform: 'rotate(5deg)',
}));

export const CustomTitle = ({ props }) => {
	const { icon, text, reverse, centerText, remoteIcon } = props;
	const { ref, inView } = useInView({
		threshold: 1,
	});
	const imageAnimation = {
		variants: {
			visible: {
				rotate: '360deg',
				transition: { delay: 0.5, duration: 1.15 },
			},
			hidden: {
				rotate: 0,
			},
		},
		initial: !inView ? 'visible' : 'hidden',
		animate: inView ? 'visible' : 'hidden',
	};
	const titleAnimation = {
		variants: {
			visible: () => ({
				x: 0,
				opacity: 1,
				transition: { duration: 1.15, delay: 0.5 },
			}),
			hidden: {
				x: '50vh',
				opacity: 0,
			},
		},
		initial: !inView ? 'visible' : 'hidden',
		animate: inView ? 'visible' : 'hidden',
	};

	return (
		<Grid
			item
			xs={12}
			sx={{ position: 'relative', p: 2 }}
			display={'flex'}
			justifyContent={centerText ? 'inherit' : 'center'}
			ref={ref}>
			<Box sx={{ display: 'flex', alignItems: 'center' }}>
				{reverse && (
					<motion.div {...imageAnimation}>
						{icon && (
							<StyledBoxImage
								component={'img'}
								src={remoteIcon ? homepageImage(icon) : icon}
							/>
						)}
					</motion.div>
				)}
				<Box>
					{Object.keys(text).length &&
						Object.keys(text).map((sentence, index) => (
							<Box
								key={`${sentence}-${index}`}
								sx={{
									marginLeft: { md: 2 },
									display: 'flex',
									justifyContent: 'start',
								}}>
								{text[sentence].split(' ').map((element, i) => (
									<motion.span
										key={`${i}${element}`}
										{...titleAnimation}
										className='view-flex'>
										<StyledTitle
											customText={`${element.charAt(0)}`}
											normalText={`${element.replace(/^./, '')}`}
											variant={`h3`}
										/>
										<Typography variant={'h3'}>&nbsp;</Typography>
									</motion.span>
								))}
							</Box>
						))}
				</Box>
				{!reverse && (
					<motion.div {...imageAnimation}>
						{icon && (
							<StyledBoxImage component={'img'} src={homepageImage(icon)} />
						)}
					</motion.div>
				)}
			</Box>
		</Grid>
	);
};
