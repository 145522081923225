import { CalendarViewDay, Replay } from '@material-ui/icons';
import { Box, Button, Drawer } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTheme } from '../../../services/useTheme';
import { DrawRounded } from '@mui/icons-material';
import FilterDialog from '../../../components/dialog/FilterDialog';

export const FiltreFormationComponent = ({
	handleDurationChange,
	handleThemeChange,
	removeFilter,
	filterTheme,
	filterDuration,
}) => {
	const [open, setOpen] = useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const hourFilter = ['02', '03', '04', '05', '06'];
	const [theme, setTheme] = useState([]);
	useEffect(() => {
		useTheme.fetchAllTheme().then((data) => {
			setTheme(data);
		});
	}, []);
	return (
		<>
			<Box sx={{ display: { lg: 'none' } }}>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					Filtrer : <CalendarViewDay onClick={() => handleClickOpen()} />
				</div>
			</Box>
			<FilterDialog
				open={open}
				handleClose={handleClose}
				theme={theme}
				handleDurationChange={handleDurationChange}
				removeFilter={removeFilter}
				handleThemeChange={handleThemeChange}
				hourFilter={hourFilter}
				filterTheme={filterTheme}
				filterDuration={filterDuration}
			/>
			<Box sx={{ display: { xs: 'none', lg: 'block' } }}>
				<div style={{ border: '1px solid grey', padding: '20px' }}>
					<Button
						component='label'
						variant='contained'
						color='secondary'
						onClick={() => removeFilter()}>
						<Replay />
						Réinitialiser
					</Button>

					<div className='hour-filter'>
						<h4>Nombre d'heures :</h4>
						{hourFilter.map((hour, index) => {
							return (
								<div key={index}>
									<input
										type='radio'
										name='hour'
										onChange={(e) => handleDurationChange(e.target.value)}
										value={hour}
									/>
									<label>{hour}h</label>
								</div>
							);
						})}
						<div>
							<label>Préciser le nombre exacte</label>
							<input
								type='number'
								onKeyDown={(e) => {
									if (e.keyCode === 13) handleDurationChange(e.target.value);
								}}
								style={{ border: 'none', borderBottom: '1px dashed black' }}
							/>
						</div>
					</div>
					<div className='theme-filter'>
						<h4>Thème :</h4>
						{theme.map((thematique, index) => (
							<div key={index}>
								<input
									type='checkbox'
									name='label'
									value={thematique.label}
									onChange={(e) => handleThemeChange(e.target)}
								/>
								<label>{thematique.label}</label>
							</div>
						))}
					</div>
				</div>
			</Box>
		</>
	);
};
