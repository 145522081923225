import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../features/useLoading';
import { Box, Grid, Typography } from '@mui/material';
import { CustomActionGrid } from '../../components/grid';
import {
	Background,
	MapimAction,
	MapimActionType,
	MapimSatisfaction,
	MapimPublicity,
	MapimApp,
} from '../../components/block';
import { logicielPageData } from '../../constants/vitrine/logicielpageData';

export const SoftwarePage = () => {
	const dispatch = useDispatch();
	const allSection = logicielPageData();
	setTimeout(() => {
		dispatch(setLoading(false));
	}, 2000);
	return (
		<Box sx={{ width: '100%' }}>
			{/* <Grid container justifyContent='center' alignItems='center' spacing={0.5}>
				<CustomActionGrid width={150} height={350} />
				<Grid item xs={12} display={'block'} position={'relative'}>
					<Background />
					{allSection &&
						allSection.map((section, index) => {
							if (section.type === 'information')
								return <MapimApp key={index} page={section} />;
							else if (section.type === 'action')
								return <MapimAction key={index} page={section} />;
							else if (section.type === 'client_satisfaction')
								return <MapimSatisfaction key={index} page={section} />;
							else if (section.type === 'action_type')
								return <MapimPublicity key={index} page={section} />;
						})}
				</Grid>
			</Grid> */}
			<Typography variant='h1' textAlign={'center'} fontFamily={'Helvetica'}>
				Disponible bientôt
			</Typography>
		</Box>
	);
};
