import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import './style.css';

export const StyleCircle = ({percentage}) => {
  const circularPBarRef = useRef(null);
  const circularPBarCounterRef = useRef(null);

  useEffect(() => {


    const tm = gsap.timeline({
      defaults: {
        duration: 10,
      ease: 'expo.out'
      },
      scrollTrigger: {
        trigger: circularPBarRef.current,
        toggleActions: "play pause resume reset"
      }
    });

    tm.fromTo(
      circularPBarCounterRef.current,
      { textContent: 0 },
      {
        textContent: percentage,
        roundProps: 'textContent',
        modifiers: {
          textContent: (value) => Math.round(value)+"%",
        },
      }
    );
    
    tm.to(circularPBarRef.current, {
      "--p": percentage+"%",
    }, 0);
  }, [percentage]);

  return (
    <h4 ref={circularPBarRef}  className="circular-pbar">
      <span ref={circularPBarCounterRef} className="circular-pbar-counter">{percentage}</span>
    </h4>
  );
};