import React, {useEffect, useState} from "react";
import { Box, Button, Typography } from "@mui/material";
import { PRIMARY, SECONDARY } from "../../theme/utils/color";
import { useDispatch } from "react-redux";
import { setLoading } from "../../features/useLoading";
import { useCommentaire } from "../../services/useCommentaire";

function QuestionBox ({ courseName, question , commentaire }) {
    const [isModalOpen, setIsModalOpen] = useState(false);  
    console.log(commentaire.responses)
    const openModal = () => {
      setIsModalOpen(true);
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
    };
    return (
        
        <Box sx={{ padding: '20px', border: '2px solid black', borderRadius: '15px', position: 'relative', marginBottom: '20px' }}>
            <Typography sx={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '5px' }}>{courseName}</Typography>
            <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>Question : </Typography>
            <Typography>{question}</Typography>
{
commentaire.responses.length === 0 ? (
    <Button   disabled
    sx={{ backgroundColor: PRIMARY.gray, color: 'white', position: 'absolute', right: '10px', bottom: '10px', borderRadius: '5px' }}
    
   >Voir les réponses</Button>
) : (

        isModalOpen === false ? (
            <Button  
            sx={{ backgroundColor: PRIMARY.green, color: 'white', position: 'absolute', right: '10px', bottom: '10px', borderRadius: '5px' }}
            onClick={openModal}
           
           >Voir les réponses</Button>
        ) : (
            <Button  
            sx={{ backgroundColor: PRIMARY.info, color: 'white', position: 'absolute', right: '10px', bottom: '10px', borderRadius: '5px' }}
            onClick={closeModal}
           >Fermer</Button>
        )

   
)
}
           
{isModalOpen && (
        <div className="modal">
         <Box sx={{ padding: '5px', borderRadius: '15px', position: 'relative', marginBottom: '20px' }}>
             <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>Reponse : </Typography>

          {commentaire.responses.map((response, index) => (
              <div key={index}>
                <Typography> {response.text}</Typography>
              </div>
            ))}

            </Box> 
        </div>
      )}
        </Box>
    );
}

export const QuestionsPage = () => {
    const dispatch = useDispatch();
	setTimeout(() => {
		dispatch(setLoading(false));
	}, 1500);
     const [comments, setComments]  = useState([]);
     useEffect(() => {
		useCommentaire.getAllComments().then((data) => {
            const commentsArray = Object.values(data.data);
            
			setComments(commentsArray);

			dispatch(setLoading(false));
		});
	}, []);
    console.log(comments);
    return (
        <Box sx={{ padding: "32px 48px" }}>
            <Typography sx={{ marginBottom: '20px' }} variant={'h4'}>Mes Questions-Réponses (Q&R)</Typography>
        {comments.map((commentaire, index) => (
 <QuestionBox
 key={index}
 courseName={commentaire.question.formation.title}
 question={commentaire.question.text}
 commentaire ={commentaire}
/>
        )   
        )}
        </Box>
    );
}
