import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { PRIMARY } from '../../theme/utils/color';
import Moment from 'react-moment';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../features/useLoading';
import { useOrder } from '../../services/useOrder';

Moment.globalLocale = 'fr';

function InvoiceBox({ title, invoice }) {
	const download = (id) => {
		useOrder.downloadInvoice(id);
	};
	return (
		<Box
			sx={{
				padding: '20px',
				border: '2px solid black',
				borderRadius: '15px',
				position: 'relative',
				marginX: 'auto',
				display: 'flex',
				flexWrap: 'wrap',
				gap: '30px',
				alignItems: 'center',
				justifyContent: {
					xs: 'center',
					lg: 'space-between',
				},
			}}>
			<Typography
				sx={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '5px' }}>
				{title} {invoice.num_invoice}
			</Typography>
			<Moment format='D MMM YYYY HH:MM'>{invoice.created_at}</Moment>
			<Typography>
				Durée : {invoice.total_duration} - Total : {invoice.total_amount}
			</Typography>
			<Button
				sx={{
					backgroundColor: PRIMARY.green,
					color: 'white',
					right: '10px',
					bottom: '10px',
					borderRadius: '5px',
				}}
				onClick={() => download(invoice.id)}>
				<DownloadIcon />
				<Typography
					component={'span'}
					sx={{ display: { xs: 'none', lg: 'inherit' } }}>
					Télécharger
				</Typography>
			</Button>
		</Box>
	);
}
export const InvoicePage = () => {
	const dispatch = useDispatch();
	setTimeout(() => {
		dispatch(setLoading(false));
	}, 1500);
	const [invoice, setInvoice] = useState();
	useEffect(() => {
		useOrder.fetchAllOrders().then((data) => {
			setInvoice(data.data);
			dispatch(setLoading(false));
		});
	}, []);
	return (
		invoice && (
			<Box sx={{ padding: '32px 0 32px 48px' }}>
				<Typography variant={'h4'}>Mes factures de paiement</Typography>
				{invoice.map((invoice, index) => (
					<div key={index} style={{ margin: '15px auto' }}>
						<InvoiceBox title='Facture N°' invoice={invoice} />
					</div>
				))}
			</Box>
		)
	);
};
