import { createHttp } from '../api/http';
const http = createHttp();

export const useCommentaire = {
	fetchCommentsById: async (formationId) => {
		return await http.get(`/commentaire/${formationId}`);
	},

	postComment: async (comment) => {
		return await http.post(`/commentaire/store`, comment);
	},

	getAllComments: async () => {
		return await http.get(`/commentaire`);
	} 
};
