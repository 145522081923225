import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate } from 'react-router-dom';

export const AppSiteMenu = ({ page, anchor, handleToggleDrawer }) => {
	const toggleDrawer = (open) => (event) => {
		if (
			event.type === 'keydown' &&
			(event.key === 'Tab' || event.key === 'Shift')
		) {
			return;
		}
		handleToggleDrawer(open);
	};

	const navigateTo = useNavigate();

	const list = () => (
		<Box onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
			<List>
				{page.map((text, index) => (
					<ListItem
						key={`${text.libelle}${index}`}
						disablePadding
						onClick={() => navigateTo(text.value)}>
						<ListItemButton>
							<ListItemText primary={text.libelle} />
						</ListItemButton>
					</ListItem>
				))}
			</List>
		</Box>
	);

	return (
		<Drawer anchor={'left'} open={anchor} onClose={toggleDrawer(false)}>
			{list()}
		</Drawer>
	);
};
