import React, { useEffect, useState } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import { Box, Button, Stack, styled, Typography } from '@mui/material';
import { PRIMARY } from '../../theme/utils/color';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../features/useLoading';
import { createHttp } from '../../api/http';
import moment from 'moment';

const DownloadButton = styled(Button)(({ theme }) => ({
	// Reset CSS for this button
	textTransform: 'none',

	// Custom CSS
	display: 'flex',
	justifyContent: 'flex-start',
	width: '100%',
	gap: '10px',
	padding: '5px 15px',
	borderRadius: '10px',
	backgroundColor: PRIMARY.green,
	color: `#fff`,
	'&:hover': {
		backgroundColor: PRIMARY.green,
	},
}));

export const CertificatesPage = () => {
	const dispatch = useDispatch();
	setTimeout(() => dispatch(setLoading(false)), 1500);
	const [attestations, setAttestations] = useState();
	const http = createHttp();
	const donwloadAttestation = async (id) => {
		window.open(process.env.REACT_APP_BASE_API_URL + '/attestation/' + id);
	};
	useEffect(() => {
		http.get('/attestation').then((response) => {
			setAttestations(response.data);
		});
	}, []);
	return (
		attestations && (
			<Box sx={{ padding: '32px 0 32px 48px' }}>
				<Typography variant={'h4'}>Mes attestations Loi Alur</Typography>
				<Stack sx={{ marginTop: '10px' }}>
					{attestations.map((attestation, index) => (
						<Box
							key={index}
							sx={{
								display: 'flex',
								marginTop: '20px',
								width: '90%',
								justifyContent: 'center',
							}}>
							<Typography
								sx={{
									borderRadius: 2,
									border: '2px solid black',
									padding: '5px 10px',
									textOverflow: 'ellipsis',
									width: '50%',
									whiteSpace: 'nowrap',
									overflow: 'hidden',
								}}>
								Attestation du {moment(attestation.updated_at).format('ll')}
							</Typography>
							<DownloadButton
								onClick={() => donwloadAttestation(attestation.id)}
								sx={{ width: 'max-content', marginLeft: '50px' }}>
								<DownloadIcon />
								<Typography
									component={'span'}
									sx={{ display: { xs: 'none', lg: 'inherit' } }}>
									Télécharger
								</Typography>
							</DownloadButton>
						</Box>
					))}
				</Stack>
			</Box>
		)
	);
};
