import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { HomePage } from '../../pages/site/HomePage';
import { AppSiteFooter, AppSiteHeader } from '../../components/navigation';
import { siteFooterNavigation, siteNavigation } from '../../constants';
import { ModulesPage } from '../../pages/site/ModulesPage';
import { OffersPage } from '../../pages/site/OffersPage';
import { CartPage } from '../../pages/site/CartPage';
import { OrderPage } from '../../pages/site/OrderPage';
import { Banner } from '../../components/block';
import { SoftwarePage } from '../../pages/site/SoftwarePage';
import { CourseComponent } from '../../pages/formation/components/CourseComponent';
import { PrivacyPolicyPage } from '../../pages/site/PrivacyPolicyPage';
import { RgpdPage } from '../../pages/site/RgpdPage';
import { CookiesPolicyPage } from '../../pages/site/CookiesPolicyPage';

export const SiteRoute = () => {
	return (
		<HelmetProvider>
			<AppSiteHeader page={siteNavigation} />
			<Banner />
			<Routes>
				<Route path='/' element={<HomePage />} />
				<Route path='/nos_modules' element={<ModulesPage />} />
				<Route path='/nos_modules/:courseName' element={<CourseComponent />} />
				<Route path='/logiciel' element={<SoftwarePage />} />
				<Route path='/offers' element={<OffersPage />} />
				<Route path='/panier' element={<CartPage />} />
				<Route path='/commande' element={<OrderPage />} />
				<Route path='/privacy-policy' element={<PrivacyPolicyPage />} />
				<Route path='/rgpd' element={<RgpdPage />} />
				<Route path='/cookies-policy' element={<CookiesPolicyPage />} />
			</Routes>
			<AppSiteFooter page={siteFooterNavigation} />
		</HelmetProvider>
	);
};
