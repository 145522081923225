import {
	Grid,
	Typography,
	Container,
	styled,
	Box,
	Button,
} from '@mui/material';
import React, { useEffect, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {
	backgrounds,
	homeImageBackground,
	homepageImage,
	icons,
	staticimages,
} from '../../../constants/staticImages';
import theme from '../../../theme';
import { CustomTitle } from '../../utils';
import { CustomImageGrid } from '../../grid';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';

const StyledContainer = styled(Container)(() => ({
	backgroundSize: 'cover',
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'center',
	position: 'relative',
	marginBottom: '5%',
}));

const StyledBackgroundGrid = styled(Grid)(() => ({
	height: '100%',
	width: '100%',
	zIndex: -1,
	position: 'absolute',
	// direction: 'rtl',
	left: 0,
	top: 0,
	marginTop: '5%',
	display: 'block',
}));

const StyledButton = styled(Button)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	backgroundColor: theme.palette.primary.info,
	color: theme.palette.primary.light,
	fontSize: theme.typography.subtitle1.fontSize,
	borderRadius: '50px',
}));

gsap.registerPlugin(ScrollTrigger);

export const MapimApp = ({ page }) => {
	const currentPage = page;
	const navigate = useNavigate();

	const handleNavigate = (value) => {
		if (value.target) {
			navigateTo(value.value);
			return;
		}
		navigate(value.value);
	};

	const navigateTo = (newPageUrl) => {
		window.open(newPageUrl, '_blank'); //to open new page
	};

	useLayoutEffect(() => {
		const tl = gsap.timeline({
			scrollTrigger: {
				trigger: '#homepage-' + currentPage.id,
				toggleActions: 'restart restart restart restart',
			},
		});
		tl.from('.image-homepage-' + currentPage.id, {
			opacity: 0,
			scale: 0,
		});
		tl.to('.image-homepage-' + currentPage.id, {
			duration: 2,
			scale: currentPage.imageScale,
			opacity: 1,
		});
	}, []);
	return (
		<StyledContainer
			maxWidth={false}
			id={'homepage-' + currentPage.id}
			sx={{
				px: 0,
				marginTop: 2,
				backgroundColor: { xs: '#f0f8ffc7', md: 'transparent' },
			}}>
			{Object.keys(currentPage.image) && (
				<StyledBackgroundGrid
					container
					sx={{ direction: (currentPage.reverse && 'ltr') || 'rtl' }}
					spacing={2}>
					{Object.keys(currentPage.image).map((image, idx) => (
						<Grid
							xs={12}
							md={6}
							alignSelf={'end'}
							key={idx}
							sx={{
								maxHeight: `${100 / Object.keys(currentPage.image).length}%`,
								position: 'relative',
								display: 'flex',
								justifyContent: 'center',
							}}
							item>
							<Box
								component={'img'}
								className={'image-homepage-' + currentPage.id}
								src={
									currentPage.isSoftware
										? homeImageBackground(currentPage.image[image])
										: currentPage.image[image]
								}
								sx={{
									maxHeight: '100%',
									width: '100%',
									scale: `${currentPage.imageScale || 1}`,
									objectFit: 'contain',
								}}></Box>
						</Grid>
					))}
				</StyledBackgroundGrid>
			)}
			<Grid
				container
				sx={{
					px: 0,
					marginTop: 2,
					display: 'flex',
					flexDirection: Object.keys(currentPage.image).length
						? 'column'
						: 'row',
					alignItems: (currentPage.reverse && 'end') || 'start',
				}}>
				{(Object.keys(currentPage.object.title).length && (
					<CustomTitle
						props={{
							remoteIcon: currentPage.remote_icon,
							icon: currentPage.icon,
							text: currentPage.object.title,
							reverse: currentPage.reverse,
							centerText: Object.keys(currentPage.image).length,
						}}
					/>
				)) ||
					''}

				<Grid
					item
					xs={12}
					sx={{ position: 'relative', width: { sm: '100%', md: '50%' } }}
					md={Object.keys(currentPage.image).length ? 10 : 12}>
					{currentPage.object.image && (
						<Box
							component={'img'}
							className={'image-homepage-' + currentPage.id}
							src={
								currentPage.isSoftware
									? homeImageBackground(currentPage.object.image)
									: homepageImage(currentPage.object.image)
							}
							sx={{
								maxHeight: '100%',
								position: 'absolute',
								bottom: 0,
								[`${currentPage.reverse ? 'right' : 'left'}`]: 0,
								zIndex: -1,
								width: '80%',
								objectFit: 'contain',
							}}></Box>
					)}
					{currentPage.object.description.map(
						(description, idx) =>
							(description.type === 'images' && (
								<CustomImageGrid data={description.sentences} />
							)) || (
								<Grid
									item
									xs={12}
									key={idx}
									sx={{
										color: `${theme.palette.primary.dark}`,
										marginTop: 2,
										p: 2,
									}}>
									<Typography
										textAlign={
											(currentPage.reverse &&
												Object.keys(currentPage.image).length &&
												'end') ||
											(!Object.keys(currentPage.image).length && 'center') ||
											'start'
										}
										variant={description.type}>
										{description.sentences.split('|').map((text, idex) => (
											<Box
												key={idex}
												sx={{
													paddingRight:
														currentPage.reverse && idex === 0 && idx !== 0
															? '15px'
															: '',
												}}>
												{!currentPage.reverse && (
													<>
														{idex === 0 &&
															idx !== 0 &&
															!currentPage.reverse && (
																<span className='green-mark'></span>
															)}
														<span className='textPadding-1'>{text}</span>
													</>
												)}
												{currentPage.reverse && (
													<div
														style={{
															display: 'flex',
															alignContent: 'start',
															justifyContent: 'flex-end',
															gap: '10px',
														}}>
														<span className='textPadding-1 text-right'>
															{text}
														</span>
														{idex === 0 && idx !== 0 && (
															<span
																className='green-mark'
																style={{
																	width: '15px',
																	height: '15px',
																	marginTop: '5px',
																}}></span>
														)}
													</div>
												)}
												<br />
											</Box>
										))}
									</Typography>
								</Grid>
							)
					)}

					<Grid
						item
						xs={12}
						sx={{
							color: `${theme.palette.primary.dark}`,
							marginTop: 3,
							display: 'flex',
							justifyContent: (currentPage.reverse && 'end') || 'start',
							p: 2,
						}}>
						{currentPage.action && (
							<StyledButton
								onClick={() => handleNavigate(currentPage.action)}
								sx={{ px: 3, py: 1 }}
								variant='contained'
								size='large'>
								<Typography variant='h6'>
									{currentPage.action.libelle}
								</Typography>
							</StyledButton>
						)}
					</Grid>
					{currentPage.object.social && (
						<Grid
							item
							xs={12}
							sx={{ display: 'flex', justifyContent: 'center' }}>
							{currentPage.object.social.map((element, idx) => (
								<Box
									component={'img'}
									className={'image-homepage-' + currentPage.id}
									sx={{
										maxHeight: 50,
										marginRight: 2,
										cursor: 'pointer',
									}}
									onClick={() => navigateTo(element.url)}
									src={homepageImage(element.icon)}
									key={idx}
								/>
							))}
						</Grid>
					)}
				</Grid>
			</Grid>
		</StyledContainer>
	);
};
