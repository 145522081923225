import React, { useEffect, useState } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { AccessTime } from '@material-ui/icons';
import ListIcon from '@mui/icons-material/List';
import { useNavigate } from 'react-router-dom';
import { useFormation } from '../../services/useFormation';
import { images } from '../../constants/staticImages';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../features/useLoading';
import { FiltreFormationComponent } from '../formation/components/FiltreFormationComponent';

function CourseBox({ name, duration, tag, link, navigate, photo, formation }) {
	return (
		<Box
			sx={{
				textAlign: 'center',
				height: '450px',
			}}>
			<Box
				component={'img'}
				src={images(photo)}
				sx={{
					borderRadius: '25px',
					width: '100%',
					height: '50%',
					border: '1px solid black',
					objectFit: 'cover',
					cursor: 'pointer',
				}}
				onClick={() => navigate(link)}></Box>
			<Typography variant='h3' sx={{ fontWeight: 'bold' }}>
				{name}
			</Typography>
			<Box
				sx={{
					display: 'flex',
					flexDirection: { sm: 'column', md: 'row', lg: 'rows' },
					alignItems: 'center',
					justifyContent: 'space-between',
					gap: '20px',
				}}>
				<Box
					width={'50%'}
					sx={{
						textAlign: 'center',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}>
					<AccessTime />
					<Typography variant='span' sx={{ fontSize: '20px' }}>
						{duration}
					</Typography>
				</Box>
				<Box width={'50%'} display={'flex'} alignItems={'center'}>
					<div>
						<ListIcon />
					</div>
					<ul>
						<select>
							{JSON.parse(formation.theme).map((theme, index) => {
								return <option key={index}>{theme}</option>;
							})}
						</select>
					</ul>
				</Box>
			</Box>
			{/*

			<Box
				sx={{
					display: 'flex',
					flexDirection: { sm: 'column', md: 'row', lg: 'rows' },
					alignItems: 'center',
					gap: '20px',
				}}>
				<Box
					sx={{
						gap: '5px',
						padding: '25px',
						alignItems: 'center',
					}}>
					<AccessTime />
					<Typography sx={{ fontSize: '20px' }}>{duration}</Typography>
				</Box>
				<Box
					sx={{
						display: 'flex',
						gap: '5px',
						alignItems: 'center',
					}}>
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<ListIcon />
					</div>
					<>
						<ul>
							{JSON.parse(formation.theme).map((theme, index) => {
								return <li key={index}>{theme}</li>;
							})}
						</ul>
					</>
				</Box>
			</Box> */}
		</Box>
	);
}

export const ModulesPage = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [filterDuration, setFilterDuration] = useState();
	const [filterTheme, setFilterTheme] = useState([]);
	setTimeout(() => dispatch(setLoading(false)), 1000);
	const [formations, setFormations] = useState([]);

	useEffect(() => {
		retrieveFormations();
	}, [filterDuration, filterTheme, filterTheme.length]);

	const handleDurationChange = (value) => {
		setFilterDuration(value);
	};
	const handleThemeChange = (target) => {
		if (target.checked) {
			if (filterTheme.indexOf(target.value) === -1) {
				setFilterTheme((prev) => [...prev, target.value].sort());
			}
		} else {
			if (filterTheme.indexOf(target.value) !== -1) {
				const currentFilterTheme = filterTheme.filter(
					(value) => value !== target.value
				);
				setFilterTheme(currentFilterTheme.sort());
			}
		}
	};

	const retrieveFormations = () => {
		useFormation
			.fetchAllFormation()
			.then((response) => {
				let responseData = response.data;

				if (!filterDuration && filterTheme.length === 0) {
					setFormations(responseData);
					return;
				}
				// Ajout de filtre pour la durrée
				if (filterDuration && filterTheme.length === 0) {
					responseData = responseData.filter(
						(formation) => formation.duration.split(':')[0] === filterDuration
					);
				}
				if (!filterDuration && filterTheme.length !== 0) {
					responseData = responseData.filter((formation) => {
						const labels = JSON.parse(formation.theme);
						if (labels.length > filterTheme) {
							let found = false;
							filterTheme.map((label) => {
								if (labels.include(label)) found = true;
								return label;
							});
							return found;
						}

						let found = false;
						labels.map((label) => {
							if (filterTheme.includes(label)) found = true;
							return label;
						});
						return found;
					});
				}

				if (filterDuration && filterTheme.length !== 0) {
					responseData = responseData.filter(
						(formation) => formation.duration === filterDuration
					);

					responseData = responseData.filter((formation) => {
						const labels = JSON.parse(formation.theme);
						if (labels.length > filterTheme) {
							let found = false;
							filterTheme.map((label) => {
								if (labels.include(label)) found = true;
								return label;
							});
							return found;
						}

						let found = false;
						labels.map((label) => {
							if (filterTheme.includes(label)) found = true;
							return label;
						});
						return found;
					});
				}
				setFormations(responseData);
			})
			.catch((e) => {
				console.log(e);
			});
	};

	const removeFilter = () => {
		setFilterTheme([]);
		setFilterDuration();
	};
	return (
		<Container
			sx={{
				width: '100%',
				maxWidth: '100% !important',
				marginTop: '100px',
				display: 'flex',
				flexDirection: { xs: 'column', lg: 'row' },
				gap: '50px',
				marginX: '0px',
			}}>
			<Box sx={{ width: { xs: '100%', lg: '20%', xl: '20%' } }}>
				<FiltreFormationComponent
					handleDurationChange={handleDurationChange}
					handleThemeChange={handleThemeChange}
					removeFilter={removeFilter}
					filterTheme={filterTheme}
					filterDuration={filterDuration}
				/>
			</Box>
			<Box
				sx={{
					width: { xs: '100%', lg: '70%', xl: '70%' },
					display: 'grid',
					gridTemplateColumns: {
						lg: 'repeat(auto-fill, 30%)',
						xl: 'repeat(auto-fill, 30%)',
						sm: '100%',
						xs: '100%',
					},
					height: '100%',
					gap: '30px',
				}}>
				{formations &&
					formations.map((formation, index) => (
						<CourseBox
							name={formation.title}
							duration={formation.duration}
							tag={formation.labels}
							photo={formation.photo}
							navigate={navigate}
							key={index}
							formation={formation}
							link={`/nos_modules/${formation.id}/`}
						/>
					))}
			</Box>
		</Container>
	);
};
