import { createHttp } from '../api/http';
const http = createHttp();

export const MessageService = {
	async sendMessage(email, fullname, subject, phoneNumber, message) {
		await http.post('/contact-us', {
			email,
			fullname,
			subject,
			phoneNumber,
			message,
		});
	},
};
