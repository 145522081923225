import React, { useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Route, Routes, useLocation, useParams } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import { Box } from '@mui/material';
import { Menu } from '@material-ui/icons';

import { BoardPage } from '../../pages/formation/BoardPage';
import { InvoicePage } from '../../pages/formation/InvoicePage';
import { CertificatesPage } from '../../pages/formation/CertificatesPage';
import { DashboardHeader } from '../../pages/formation/components/DashboardHeader';
import { DashboardSidebar } from '../../pages/formation/components/DashboardSidebar';
import { DashboardContent } from '../../pages/formation/components/DashboardContent';
import { CoursesPage } from '../../pages/formation/CoursesPage';
import { CoursePage } from '../../pages/formation/CoursePage';
import { CourseComponent } from '../../pages/formation/components/CourseComponent';
import { QuestionsPage } from '../../pages/formation/QuestionsPage';
import { Profile } from '../../pages/profile/Profile';
import { QuizPage } from '../../pages/formation/QuizPage';
import { QuizzPage } from '../../pages/quizz/QuizzPage';

export const DashboardRoute = () => {
	const [showSidebar, setShowSidebar] = useState(false);
	const [customContent, setCustomContent] = useState(null);

	const url = useParams();
	const { pathname } = useLocation();
	const numberOfParams = url['*'].split('/');
	const [top, setTop] = useState(
		Math.max(0, 126 - document.documentElement.scrollTop)
	);

	window.addEventListener('scroll', () => {
		setTop(Math.max(0, 126 - document.documentElement.scrollTop));
	});

	return (
		<HelmetProvider>
			<DashboardHeader />
			<Box
				sx={{
					position: 'relative',
					height: '100%',
					maxWidth: '100%',
					display: 'flex',
				}}>
				<DashboardSidebar
					open={showSidebar}
					setOpen={setShowSidebar}
					top={top}
					customContent={customContent}
				/>
				<DashboardContent open={showSidebar}>
					{!showSidebar &&
						pathname.includes('/dashboard') &&
						numberOfParams.length !== 3 && (
							<IconButton
								sx={{ position: 'absolute', top: 5, left: 5 }}
								onClick={() => setShowSidebar(true)}>
								<Menu />
							</IconButton>
						)}
					<Routes>
						<Route path='/' element={<BoardPage />}></Route>
						<Route path='/certificates' element={<CertificatesPage />}></Route>
						<Route path='/profile' element={<Profile />}></Route>
						<Route path='/faq' element={<QuestionsPage />}></Route>
						<Route path='/quiz' element={<QuizPage />}></Route>
						<Route path='/courses' element={<CoursesPage />}></Route>
						<Route path='/factures' element={<InvoicePage />}></Route>
						<Route
							path='/courses/:courseName'
							element={<CourseComponent top={top} />}
						/>
						<Route
							path='/courses/:courseName/:lesson'
							element={
								<CoursePage
									customContent={customContent}
									setCustomContent={setCustomContent}
								/>
							}
						/>
						<Route
							path='/quizz/:formationUuid/'
							element={
								<QuizzPage
									customContent={customContent}
									setCustomContent={setCustomContent}
								/>
							}
						/>
					</Routes>
				</DashboardContent>
			</Box>
		</HelmetProvider>
	);
};
