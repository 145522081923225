export const siteNavigation = [
	{
		libelle: 'Nos modules Loi Alur',
		value: '/nos_modules',
	},
	{
		libelle: 'Nos offres',
		value: '/offers',
	},
	{
		libelle: 'Logiciel immobilier',
		value: '/logiciel',
	},
	{
		libelle: 'Contact',
		// value: '/logiciel'
	},
];

export const siteFooterNavigation = [
	{
		libelle: 'Politique RGPD',
		value: '/rgpd',
	},
	{
		libelle: 'Politique de confidentialité',
		value: '/privacy-policy',
	},
	{
		libelle: 'FAQ',
		value: null,
	},
	{
		libelle: 'Politique des cookies',
		value: '/cookies-policy',
	},
];
