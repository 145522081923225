import { Box, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../features/useLoading';

export const RgpdPage = () => {
	const dispatch = useDispatch();
	setTimeout(() => {
		dispatch(setLoading(false));
	}, 2000);
	return (
		<>
			<Typography variant='h3' paddingTop={'50px'} textAlign={'center'}>
				REGISTRE DE TRAITEMENT DES DONNEES PERSONNELLES
			</Typography>
			<Box padding={'10px'} width={{ md: '65%' }} margin={'auto auto'}>
				<h4>Détails du contact:</h4>
				<p style={{ textIndent: '30px', textAlign: 'justify' }}>
					<ul style={{ listStyleType: 'none' }}>
						<li>
							<b>Nom de l'entreprise</b> : MAPIM, 8 rue Pierre de Ronsard, 62119
							DOURGES, 8 rue Pierre de Ronsard mail: formation@mapim-immo.fr , n
							° de téléphone: 0988194762
						</li>
						<li>
							<b>Responsable du traitement</b> : Thomas MARECHAL, adresse mail :
							contact@mapim-immo.fr, tél : 0988194762
						</li>
						<li>
							<b>Délégué à la protection des données</b> : Thomas MARECHAL ,
							adresse contact@mapim-immo.fr mail: contact@mapim-immo.fr , n ° de
							téléphone: 0988194762
						</li>
					</ul>
				</p>
			</Box>
			<Box padding={'10px'} width={{ md: '65%' }} margin={'auto auto'}>
				<h4>Activité de traitement des données régulières :</h4>

				<p style={{ textIndent: '30px', textAlign: 'justify' }}>
					<ul style={{ listStyleType: 'none' }}>
						<li>
							Catégories de données personnelles: __________ Ensemble des
							données personnelles
						</li>
						<li>Catégorie de personnes concernées: Clients</li>
						<li>Base de traitement: le contrat</li>
						<li>Finalité du traitement: gestion d'une prestation de service</li>
						<li>
							Destinataire des données : un service interne de mon entreprise
						</li>
						<li>Durée de conservation des données : 10 ans</li>
						<li>
							Précautions de sécurité: Sauvegarde des données Contrôle d'accès
							des utilisateurs, Restriction d'accès, Mesures de protection des
							logiciels
						</li>
					</ul>
				</p>
			</Box>
			<Box padding={'10px'} width={{ md: '65%' }} margin={'auto auto'}>
				<span>
					<b>Activité de traitement des données personnelles sensibles</b>
				</span>{' '}
				: Non
				<br />
				<span>Date de création du registre: 01 septembre 2023</span>
				<br />
				<span>Date des mises à jour :</span>
			</Box>
		</>
	);
};
