import { configureStore } from '@reduxjs/toolkit';
import useFormation from './features/formations/useFormation';
import usePlan from './features/plan/usePlan';
import useLoading from './features/useLoading';

export const store = configureStore({
	reducer: {
		formation: useFormation,
		plan: usePlan,
		loading: useLoading,
	},
});
