import { createHttp } from '../api/http';
const http = createHttp();

export const useQuizz = {
	takeQuizz: async (quizzAnswer) => {
		return await http.post('/quizz', {
			quizz: quizzAnswer,
		});
	},
	myQuizz: async () => {
		return await http.get('/dashboard/my-quizz');
	},
};
