import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { AccessTime, Euro } from '@material-ui/icons';
import { useNavigate } from 'react-router-dom';
import { usePlan } from '../../services/usePlan';
import { ShoppingBag } from '@mui/icons-material';
import { addPlanCart } from '../../features/plan/usePlan';
import { useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { backgrounds } from '../../constants/staticImages';
import { setLoading } from '../../features/useLoading';
import { Navigate } from 'react-router-dom';

function CourseBox({ name, duration, price, photo, addCart }) {
	return (
		<Box
			sx={{
				border: '1px solid grey',
				padding: '20px',
				borderRadius: '25px',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'column',
				maxWidth: '350px',
				textAlign: 'center',
			}}>
			<Box
				sx={{
					backgroundImage: `url(${photo})`,
					backgroundPosition: 'center',
					backgroundSize: '60%',
					borderRadius: '5px',
					height: '100px',
					width: '300px',
					cursor: 'pointer',
					backgroundRepeat: 'no-repeat',
					paddingTop: '10%',
					fontSize: '70px',
				}}></Box>
			<Typography
				sx={{ fontSize: '35px', fontWeight: 'bold', maxWidth: '240px' }}>
				{price} <Euro />
				<sup>
					<small>HT</small>
				</sup>
			</Typography>
			<Typography
				sx={{ fontSize: '24px', fontWeight: 'bold', maxWidth: '240px' }}>
				{name}
			</Typography>
			<Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
				<Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
					<AccessTime />
					<Typography sx={{ fontSize: '20px' }}>{duration}</Typography>
				</Box>
				<Box
					sx={{
						display: 'flex',
						gap: '5px',
						alignItems: 'center',
						border: '1px solid black',
						padding: '5px',
						borderRadius: '25px',
						cursor: 'pointer',
					}}
					onClick={addCart}>
					<ShoppingBag />
					<Typography sx={{ fontSize: '20px' }}>Ajouter au panier</Typography>
				</Box>
			</Box>
		</Box>
	);
}

function CourseBoxSale({ name, duration, price, photo, sale_price, addCart }) {
	return (
		<Box
			sx={{
				border: '1px solid grey',
				padding: '20px',
				borderRadius: '25px',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'column',
				maxWidth: '350px',
				textAlign: 'center',
			}}>
			<Box
				sx={{
					backgroundImage: `url(${photo})`,
					backgroundPosition: 'center',
					backgroundSize: '60%',
					borderRadius: '5px',
					height: '100px',
					width: '300px',
					cursor: 'pointer',
					backgroundRepeat: 'no-repeat',
					paddingTop: '10%',
					fontSize: '70px',
				}}></Box>
			<Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
				<Typography
					sx={{ fontSize: '35px', fontWeight: 'bold', maxWidth: '240px' }}>
					{sale_price} <Euro />
					<sup>
						<small>HT</small>
					</sup>
				</Typography>

				<Typography
					sx={{
						fontSize: '35px',
						fontWeight: 'bold',
						maxWidth: '240px',
						color: 'gray',
					}}>
					({price} <Euro />
					<sup>
						<small>HT</small>
					</sup>
					)
				</Typography>
			</Box>

			<Typography
				sx={{ fontSize: '24px', fontWeight: 'bold', maxWidth: '240px' }}>
				{name}
			</Typography>
			<Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
				<Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
					<AccessTime />
					<Typography sx={{ fontSize: '20px' }}>{duration}</Typography>
				</Box>
				<Box
					sx={{
						display: 'flex',
						gap: '5px',
						alignItems: 'center',
						border: '1px solid black',
						padding: '5px',
						borderRadius: '25px',
						cursor: 'pointer',
					}}
					onClick={addCart}>
					<ShoppingBag />
					<Typography sx={{ fontSize: '20px' }}>Ajouter au panier</Typography>
				</Box>
			</Box>
		</Box>
	);
}

export const OffersPage = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	setTimeout(() => {
		dispatch(setLoading(false));
	}, 1500);
	const [plans, setPlans] = useState();
	useEffect(() => {
		usePlan.fetchAllPlan().then((data) => {
			setPlans(data);
			dispatch(setLoading(false));
		});
	}, []);

	const handleAddToCart = (plan) => {
		dispatch(addPlanCart(plan));
		toast.success('Ajouté avec succès dans le panier!', {
			position: 'top-right',
			autoClose: 5000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: 'light',
		});
	};
	return (
		plans && (
			<Box sx={{ width: '100%', marginTop: '100px' }}>
				<Typography
					variant='h3'
					sx={{
						fontWeight: 'bold',
						textAlign: 'center',
						paddingX: { xs: '20px' },
					}}>
					Avec MAPIM Formation, Profitez de tarifs parmi les plus bas du marché
					et une qualité garantie!
				</Typography>
				<Typography
					variant='h4'
					sx={{
						fontWeight: 'thin',
						display: 'flex',
						flexWrap: 'wrap',
						paddingX: { xs: '20px' },
						alignContent: 'center',
						justifyContent: 'center',
					}}>
					Vous souhaitez opter l'un des nos packages?
				</Typography>
				<Box
					sx={{
						marginTop: '2px',
						padding: '50px',
						display: 'flex',
						flexWrap: 'wrap',
						alignContent: 'center',
						justifyContent: 'center',
						gap: '30px',
					}}>
					{plans
						.filter((plan) => plan.sale_price != null)
						.map((plan) => {
							return (
								<div key={plan.id}>
									<CourseBoxSale
										name={plan.title}
										duration={plan.duration}
										price={plan.price}
										sale_price={plan.sale_price}
										photo='/logo-formation.png'
										addCart={() => handleAddToCart(plan)}
									/>
								</div>
							);
						})}
				</Box>
				<Typography
					variant='h4'
					sx={{
						marginTop: '20px',
						fontWeight: 'thin',
						paddingX: { xs: '20px' },
						textAlign: 'center',
					}}>
					Ou avez-vous besoin d'un nombre d'heure plus spécifiques ?
				</Typography>
				<Box
					sx={{
						marginTop: '2px',
						display: 'flex',
						marginBottom: { xs: '30px' },
						flexWrap: 'wrap',
						alignContent: 'center',
						justifyContent: 'center',
						gap: '30px',
					}}>
					{plans
						.filter((plan) => plan.sale_price == null)
						.map((plan) => {
							return (
								<div key={plan.id}>
									<CourseBox
										name={plan.title}
										duration={plan.duration}
										price={plan.price}
										photo='/logo.png'
										addCart={() => handleAddToCart(plan)}
									/>
								</div>
							);
						})}
				</Box>
				<Typography
					variant='h4'
					sx={{
						fontWeight: 'thin',
						textAlign: 'center',
						paddingX: { xs: '20px' },
					}}>
					Combinez nos Packs avec des heures supplémentaires afin de profitez
					d'un tarif encore plus avantageux!
				</Typography>
			</Box>
		)
	);
};
