import React, { useEffect, useState } from "react";
import { Box, TextField, styled } from "@mui/material";
import * as MuiIcons from '@material-ui/icons'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
// import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng} from "react-google-places-autocomplete";

import './style.css'

const StyledInput = styled(TextField)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    color: "#FFF",
    marginBottom: "20px",
}));

const googleOption = {
    region: 'fr',
    language: 'fr'
}

export const CustomInput = ({
        label,
        onChange,
        name,
        required,
        type,
        defaultVal,
        props,
    }) => {

    const { customEndAdornment } = props && props.customEndAdornment ? props : {customEndAdornment : null}
    const Icon = MuiIcons[customEndAdornment]
    const [values, setValues] = useState({[name]: null})
    const [showPassword, setShowPassword] = useState(false);

    const handleChange = (event) => {
        const {name, value} = event.target
        setValues((curr) => {
            const newValue = {
                ...curr,
                [name]: value
            }
            onChange(newValue)
            return newValue
        })
    }

    const handleChangeAdress = (event) => {
        // const place = event.label
        // geocodeByAddress(place)
        // .then(results => getLatLng(results[0]))
        // .then(({ lat, lng }) =>
        //     console.log('Successfully got latitude and longitude', { lat, lng })
        // );
    }

    const handleChangeTel = (event) => {
        setValues((curr) => {
            const newValue = {
                ...curr,
                telephone: event
            }
            onChange(newValue)
            return newValue
        })
    }
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    useEffect(() => {
        if(defaultVal) {
            setValues(() => ({
                [name]: defaultVal
            }))
        }
    }, [name, defaultVal])

    return (
        <Box sx={{ width: '100%', mb: 1, p: 0}}>
            {
                (
                    type === 'telephone' &&
                    <PhoneInput
                        onChange={handleChangeTel}
                        value={values[name]}
                    />
                )
                ||
                // (
                //     type === 'adresse' &&
                //     <GooglePlacesAutocomplete
                //         apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                //         apiOptions={googleOption}
                //         selectProps={{
                //             onChange: handleChangeAdress,
                //           }}
                //         style={{ width: '100%', padding: "8px", margin: "5px"}}
                //         className={`PhoneInputInput`}
                //     />
                // )
                // ||
                (
                    type === 'longtext' &&
                    <StyledInput
                        label={label}
                        size="small"
                        fullWidth
                        onChange={handleChange}
                        name={name}
                        type={(type === 'password' && showPassword && 'text') || type}
                        required={required}
                        multiline
                        rows={4}
                        defaultValue={(defaultVal && defaultVal) || values[name]}
                        InputProps={Icon && {
                            endAdornment : <Icon onClick={(type === 'password' && handleClickShowPassword) || null}/>
                        }}
                        className={`custom-input`}
                    />
                )
                ||
                (
                    <StyledInput
                        label={label}
                        size="small"
                        fullWidth
                        onChange={handleChange}
                        name={name}
                        type={(type === 'password' && showPassword && 'text') || type}
                        required={required}
                        defaultValue={(defaultVal && defaultVal) || values[name]}
                        InputProps={Icon && {
                            endAdornment : <Icon onClick={(type === 'password' && handleClickShowPassword) || null}/>
                        }}
                        className={`custom-input`}
                    />
                )
            }
        </Box>
    )
}
