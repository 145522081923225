export const PRIMARY = {
    light: '#D6E0E2',
    dark : '#333237',
    main: '#68BBA1',
    contrastText: "#fff",
    success: '#8A9401',
    info: '#2D5C6A',
    green:  '#8A9401',
}

export const SECONDARY = {
    dark: '#333237',
    main: '#68BBA1',
    light: '8E9200',
    contrastText: "#fff",
    success: '#8A9401',
    info: '#2D5C6A',
    green:  '#8A9401',
}