import { Navigate } from 'react-router-dom';
import { AuthStorage } from '../../utils/AuthStorage';
import { createHttp } from '../../api/http';
import { useDispatch } from 'react-redux';
import { fetchAllFormations } from '../../features/formations/useFormation';
import { useState } from 'react';
import { setLoading } from '../../features/useLoading';

const AuthenticatedRoute = ({ children }) => {
	const user = AuthStorage.isAuth();
	const dispatch = useDispatch();
	const [isLoaded, setIsLoaded] = useState(false);

	if (!user) {
		return <Navigate to='/auth/login' />;
	} else {
		const http = createHttp();
		const getAllFormations = async () => {
			if (isLoaded === false) {
				const res = await http.get('/dashboard/my-plateforme');
				dispatch(fetchAllFormations(res.data));
				setIsLoaded(true);
				// setTimeout(() => {
				// 	dispatch(setLoading(false));
				// }, 3000);
			}
		};
		getAllFormations();
		return children;
	}
};

export default AuthenticatedRoute;
