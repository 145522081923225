import { createTheme } from "@mui/material"
import { PRIMARY, SECONDARY } from "./utils/color"

const theme = createTheme({
    palette : {
        primary: PRIMARY,
        secondary: SECONDARY
    },
    typography: {
        fontSize: 16,
        fontFamily: ["Myriad_pro"].join(","),
        h3: {
            fontWeight: 700,
            fontSize: '2.2rem',
        },
        h4: {
            fontWeight: 700,
            fontSize: '1.80rem'
        },
        h5: {
            fontWeight: 500,
            fontSize: '1.35rem'
        },
        h6: {
            fontWeight: 400,
            fontSize: '1.25rem'
        },
        subtitle1 : {
            fontWeight: 500,
            fontSize: '1rem'
        },
        subtitle2 : {
            fontWeight: 300,
            fontSize: '0.85rem'
        }
    }
})

export default theme