import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Alert, CircularProgress, Input } from '@mui/material';
import { useState } from 'react';
import { Send } from '@material-ui/icons';
import { MessageService } from '../../services/useMessage';

export default function AlertDialog({ status, close }) {
	const [fullname, setFullname] = useState();
	const [email, setEmail] = useState();
	const [phone, setPhone] = useState();
	const [subject, setSubject] = useState();
	const [message, setMessage] = useState();
	const [isSubmiting, setIsSubmiting] = useState(false);
	const [error, setError] = useState('');

	const handleSendMessage = async () => {
		setIsSubmiting(true);
		setError('');
		if (email && fullname && subject && phone && message) {
			await MessageService.sendMessage(
				email,
				fullname,
				subject,
				phone,
				message
			);
			setTimeout(() => {
				setIsSubmiting(false);
				close();
			}, 3000);
		} else {
			if (
				!email ||
				!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
			) {
				setError("Veuillez remplir l'email avec un format valide");
				setIsSubmiting(false);
				return;
			}
			if (!fullname || fullname.length < 4) {
				setError('Le nom et prénom doit être au moins 4 caractères');
				setIsSubmiting(false);
				return;
			}
			if (!phone || phone.length < 10) {
				setError('Le numéro de téléphone doit être au moins 10 caractères');
				setIsSubmiting(false);
				return;
			}

			if (!subject || subject.length < 4) {
				setError('Le sujet doit être au moins 4 caractères');
				setIsSubmiting(false);
				return;
			}
			if (!message || message.length < 4) {
				setError('Le message doit être au moins 4 caractères');
				setIsSubmiting(false);
				return;
			}
		}
	};
	return (
		<>
			<Dialog
				fullWidth={true}
				open={status}
				onClose={close}
				sx={{
					borderRadius: '25px',
				}}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'>
				<DialogTitle
					id='alert-dialog-title'
					component={'h3'}
					sx={{
						textAlign: 'center',
						textTransform: 'uppercase',
						fontWeight: 'bold',
					}}>
					{'Laissez votre message'}
				</DialogTitle>
				<DialogContent>
					<DialogContentText component={'div'} id='alert-dialog-description'>
						<Input
							fullWidth={true}
							placeholder='Votre nom et prénom(s)'
							onChange={(e) => setFullname(e.target.value)}
						/>
						<Input
							fullWidth={true}
							type='email'
							placeholder='Votre email'
							onChange={(e) => setEmail(e.target.value)}
						/>
						<Input
							fullWidth={true}
							type='tel'
							placeholder='Votre téléphone'
							onChange={(e) => setPhone(e.target.value)}
						/>
						<Input
							fullWidth={true}
							type='text'
							placeholder='Sujet'
							onChange={(e) => setSubject(e.target.value)}
						/>
						<textarea
							style={{
								width: '100%',
								marginTop: '10px',
								borderRadius: '5px',
								border: '1px solid grey',
							}}
							onChange={(e) => setMessage(e.target.value)}
							rows={7}
							placeholder='Votre message'
						/>
					</DialogContentText>
					{error && <Alert severity='error'>{error}</Alert>}
					<p style={{ textAlign: 'right', paddingTop: '20px' }}>
						<Button
							variant='contained'
							onClick={() => handleSendMessage()}
							autoFocus
							disabled={isSubmiting}
							sx={{
								position: 'relative',
								rigth: 0,
								borderRadius: '10px',
								backgroundColor: '#8A9401',
								color: 'white',
							}}
							endIcon={isSubmiting ? <CircularProgress size={20} /> : <Send />}>
							Envoyer
						</Button>
					</p>
				</DialogContent>
			</Dialog>
		</>
	);
}
