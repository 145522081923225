import { createHttp } from '../api/http';
import { AuthStorage } from '../utils/AuthStorage';
const http = createHttp();
const token = AuthStorage.isAuth()?.token;
export const useOrder = {
	storeOrder: async (data) => await http.post(`/order`, data),
	fetchAllOrders: async () => await http.get('/order'),
	fetchOrder: async (id) => await http.get(`/order/${id}`),
	downloadInvoice: async (id) =>
		window.open(
			`${process.env.REACT_APP_BASE_API_URL}/download-invoice/${id}/${token}`,
			'_blank'
		),

};
