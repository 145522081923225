import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
	Typography,
	Container,
	Grid,
	styled,
	Button,
	Box,
} from '@mui/material';
import theme from '../../../theme';
import { backgrounds, homepageImage } from '../../../constants/staticImages';

const StyledContainer = styled(Container)(({ theme }) => ({
	backgroundSize: 'cover',
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'center',
	position: 'relative',
	padding: '0 !important',
}));

const StyledButton = styled(Button)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	backgroundColor: theme.palette.primary.info,
	color: '#FFF',
	fontSize: theme.typography.subtitle1.fontSize,
	borderRadius: '10px',
}));

const StyledBoxImage = styled(Box)(() => ({
	maxHeight: 75,
	width: '100%',
	left: 0,
	top: 0,
	padding: 1,
	transform: 'rotate(5deg)',
}));

const StyledBackgroundGrid = styled(Grid)(({ theme }) => ({
	height: '100%',
	width: '100%',
	position: 'absolute',
	background: 'blue',
	direction: 'rtl',
	left: 0,
	top: 0,
}));
export const MapimPublicity = ({ page }) => {
	const currentPage = page;
	const navigate = useNavigate();
	const handleNavigate = (value) => {
		navigate(value);
	};

	return (
		<StyledContainer
			maxWidth={false}
			sx={{
				p: 0,
				marginTop: 2,
				display: 'block',
				backgroundColor: '#2D5C6A',
				color: 'white',
			}}>
			<Grid
				item
				xs={12}
				position={'relative'}
				sx={{
					width: { md: '60%', xs: '100%', lg: '40%', xl: '40%' },
					margin: 'auto',
					textAlign: 'center',
				}}
				padding={3}>
				<Typography variant='div' margin={5} sx={{ display: 'flex' }}>
					<div>
						{currentPage.object.title.split(' ').map((title, index) => {
							return (
								<Typography
									variant='span'
									sx={{
										fontSize: '50px',
										textTransform: 'uppercase',
										fontStyle: 'oblique 20deg',
									}}
									key={index}>
									<Typography variant='span'>{title[0]}</Typography>
									<Typography variant='span'>
										{title.replace(/^./, '') + ' '}
									</Typography>{' '}
								</Typography>
							);
						})}
					</div>
				</Typography>

				<Typography variant='h5' margin={5}>
					<Box>
						{currentPage.object.description1.sentences}
						<br />
					</Box>
				</Typography>
				{currentPage.buttonLink && (
					<Grid
						item
						xs={12}
						sx={{
							marginTop: 5,
							marginBottom: 2,
							p: 2,
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}>
						<Button
							onClick={() => handleNavigate(currentPage.buttonLink)}
							color='success'
							sx={{
								paddingX: '100px',
								paddingY: '15px',
								borderRadius: '50px',
								color: 'white',
								textTransform: 'none',
								fontWeight: 'bold',
								opacity: '0.95',
							}}
							size='large'
							variant='contained'>
							{currentPage.buttonText}
						</Button>
					</Grid>
				)}

				{currentPage.object.description2 && (
					<Typography variant='h5' margin={5}>
						<Box>
							{currentPage.object.description2.sentences}
							<br />
						</Box>
					</Typography>
				)}
			</Grid>
		</StyledContainer>
	);
};
