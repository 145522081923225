import { Grid, styled, Box, Button, Typography, Badge } from '@mui/material';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { backgrounds } from '../../../constants/staticImages';
import { AppSiteMenu } from './AppSiteMenu';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { AccountBox, ShoppingBag } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { AuthStorage } from '../../../utils/AuthStorage';
import { gsap } from 'gsap';
import { VerifiedUser } from '@material-ui/icons';

const StyledGrid = styled(Grid)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	alignContent: 'center',
	padding: 0,
}));

const StyledButton = styled(Button)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	backgroundColor: theme.palette.primary.info,
	color: '#FFF',
	fontSize: theme.typography.subtitle1.fontSize,
	borderRadius: '10px',
}));

const StyledContainer = styled(Grid)(({ theme }) => ({
	position: 'fixed',
	top: 0,
	background: '#ffffff',
	zIndex: 111,
}));

export const AppSiteHeader = ({ page }) => {
	const currentPage = page;
	const [anchor, setAnchor] = useState(false);
	const navigate = useNavigate();

	const totalQuantity = useSelector((state) => state.plan.totalQuantity);

	const handleToggleDrawer = (value) => {
		setAnchor(value);
	};

	const handleNavigate = (value) => {
		navigate(value);
	};
	const navigateTo = () => {
		const newPageUrl = AuthStorage.isAuth ? '/dashboard' : '/auth/login';
		window.location.href = newPageUrl; //to open new page
	};

	useLayoutEffect(() => {
		const tl = gsap.timeline({
			repeat: -1,
		});

		tl.to('#my-logo', {
			rotateY: 360,
			opacity: 1,
			duration: 2,
		});
		tl.to('#my-logo', {
			x: '+=50',
			duration: 3,
		});
		tl.to('#my-logo', {
			opacity: 0,
			duration: 3,
			scale: 0.5,
		});
	}, []);

	return (
		<StyledContainer container spacing={2}>
			<StyledGrid item xs={4} md={2}>
				<Box
					component={'img'}
					sx={{
						maxHeight: { xs: 100 },
						// 	width: { xs: '100%' },
						objectFit: 'contain',
						opacity: 0,
					}}
					id='my-logo'
					alt='mapim-immo'
					src={`/IMG_1079.PNG`}
					padding={1}
					onClick={() => handleNavigate('/')}
				/>
			</StyledGrid>
			<StyledGrid item xs={4} md={8}>
				<Box
					sx={{
						flexGrow: 1,
						display: { xs: 'none', lg: 'flex' },
						width: '100%',
						justifyContent: 'space-around',
					}}
					alignContent={'center'}
					alignItems={'center'}>
					{currentPage.map((page, idx) => (
						<Typography
							key={idx}
							sx={{ display: 'block', cursor: 'pointer', textAlign: 'center' }}
							variant='h6'
							onClick={() => handleNavigate(page.value)}>
							{page.libelle}
						</Typography>
					))}
				</Box>
				<AppSiteMenu
					page={currentPage}
					display={{ xs: 'block', lg: 'none' }}
					anchor={anchor}
					handleToggleDrawer={handleToggleDrawer}
				/>
			</StyledGrid>
			<StyledGrid item md={2} xs={4}>
				<StyledButton
					onClick={() => navigateTo()}
					sx={{
						px: 3,
						m: 1,
						display: { xs: 'none', lg: 'flex' },
						width: '75%',
					}}
					variant='contained'
					size='large'>
					<Typography variant='h6'>MON ESPACE</Typography>
				</StyledButton>

				<Badge
					badgeContent={totalQuantity}
					sx={{
						display: { lg: 'none', xs: 'flex' },
						marginRight: '10%',
					}}
					color='primary'
					onClick={() => {
						navigateTo();
					}}>
					<AccountBox />
				</Badge>
				<Badge
					badgeContent={totalQuantity}
					color='primary'
					onClick={() => {
						navigate('/panier');
					}}>
					<ShoppingBag />
				</Badge>
				<IconButton color='inherit'></IconButton>
				<IconButton
					edge='start'
					color='inherit'
					aria-label='open drawer'
					onClick={() => handleToggleDrawer(true)}
					sx={{
						ml: 1,
						display: { xs: 'inherit', lg: 'none' },
					}}>
					<MenuIcon />
				</IconButton>
			</StyledGrid>
		</StyledContainer>
	);
};
