import React from 'react';
import {
	Typography,
	Container,
	Grid,
	styled,
	Button,
	Box,
} from '@mui/material';
import theme from '../../../theme';
import {
	icons,
	backgrounds,
	staticimages,
} from '../../../constants/staticImages';
import { useNavigate } from 'react-router-dom';

const StyledContainer = styled(Container)(({ theme }) => ({
	background: `${theme.palette.primary.info}`,
	maxWidth: 'inherit !important',
	backgroundSize: 'cover',
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'center',
	position: 'relative',
	marginTop: '5%',
}));

const StyledButton = styled(Button)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	backgroundColor: theme.palette.primary.main,
	color: `${theme.palette.primary.light}`,
	fontSize: theme.typography.subtitle1.fontSize,
	borderRadius: '10px',
}));

const socialArray = [
	{
		url: 'https://facebook.com',
		icon: 'logo-facebook-blanc.png',
	},
	{
		url: 'https://instagram.com',
		icon: 'logo-instagram-blanc.png',
	},
	{
		url: 'https://linkedin.com',
		icon: 'logo-linkedin-blanc.png',
	},
	{
		url: 'https://youtube.com',
		icon: 'logo-youtube-blanc.png',
	},
];

export const AppSiteFooter = ({ page }) => {
	const currentPage = page;
	const navigate = useNavigate();
	const navigateTo = (newPageUrl) => {
		navigate(newPageUrl); //to open new page
	};
	return (
		<StyledContainer sx={{ padding: 0, display: 'block' }}>
			<Grid
				container
				position={'relative'}
				sx={{
					background: `${theme.palette.primary.info}`,
					color: `${theme.palette.primary.light}`,
				}}
				padding={3}>
				<Grid item xs={12} md={4} sx={{ padding: 2 }}>
					<Box
						component={'img'}
						sx={{
							maxHeight: 100,
							marginRight: 1,
						}}
						src={'/IMG_1079.PNG'}
					/>
					<Grid sx={{ padding: 2, display: 'flex' }}>
						{socialArray.map((element, idx) => (
							<Box
								component={'img'}
								sx={{
									maxHeight: 30,
									marginRight: 1,
									cursor: 'pointer',
								}}
								onClick={() => navigateTo(element.url)}
								src={staticimages(`${element.icon}`)}
								key={idx}
							/>
						))}
					</Grid>
				</Grid>
				<Grid item xs={12} md={4} sx={{ padding: 2 }}>
					<Typography
						sx={{ color: `${theme.palette.primary.light}` }}
						variant='h4'
						marginBottom={5}>
						Contactez-nous
					</Typography>
					<Typography
						sx={{ cursor: 'pointer', color: `${theme.palette.primary.light}` }}
						variant='h5'
						marginY={5}>
						formation@mapim-immo.fr <br />
						09 88 19 47 62
						<br />
					</Typography>
				</Grid>
				<Grid item xs={12} md={4} sx={{ padding: 2 }}>
					<Typography
						sx={{ color: `${theme.palette.primary.light}` }}
						variant='h4'
						marginBottom={5}>
						Téléchargez notre brochure
					</Typography>
					<Typography
						sx={{ color: `${theme.palette.primary.light}` }}
						variant='h5'
						marginY={5}>
						Découvrez l'intégralité de nos services <br />
						au sein de notre brochure
						<br />
					</Typography>
					<StyledButton sx={{ px: 3 }} variant='contained' size='large'>
						<Box
							component={'img'}
							sx={{
								maxHeight: 25,
								marginRight: 1,
							}}
							src={staticimages('bouton_telecharger.png')}
						/>
						<Typography variant='h5'>TELECHARGER</Typography>
					</StyledButton>
				</Grid>
				<Grid item xs={12} md={8} sx={{ padding: 2 }}>
					<Box
						sx={{
							flexGrow: 1,
							display: { xs: 'none', md: 'flex' },
							width: '100%',
							justifyContent: 'space-center',
						}}
						alignContent={'center'}
						alignItems={'center'}>
						{currentPage.map((page, idx) => (
							<Typography
								sx={{
									display: 'block',
									cursor: 'pointer',
									textAlign: 'center',
								}}
								variant='h6'
								marginRight={3}
								onClick={() => navigateTo(page.value)}
								key={`${page.libelle}${idx}`}>
								{page.libelle}
							</Typography>
						))}
					</Box>
				</Grid>
				<Grid item xs={12} md={4} sx={{ padding: 2 }}>
					<Box
						sx={{
							flexGrow: 1,
							display: 'flex',
							width: '100%',
						}}
						alignItems={'center'}>
						<Typography
							sx={{ display: 'block', cursor: 'pointer', textAlign: 'center' }}
							variant='h6'>
							{new Date().getFullYear()} - Tous droits réservés MAPIM IMMO
						</Typography>
					</Box>
				</Grid>
			</Grid>
		</StyledContainer>
	);
};
