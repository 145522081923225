import { Grid, Typography, Container, styled, Box } from '@mui/material';
import React, { useLayoutEffect } from 'react';
import theme from '../../../theme';
import { StyledTitle } from './StyledTilte';
import {
	icons,
	backgrounds,
	homepageImage,
} from '../../../constants/staticImages';
import { motion } from 'framer-motion';
import { gsap } from 'gsap';
import { TextPlugin } from 'gsap/all';

const StyledContainer = styled(Container)(({ theme }) => ({
	maxWidth: 'inherit !important',
	// backgroundColor: "#f0f8ffc7",
	backgroundSize: 'cover',
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'center',
	position: 'relative',
	marginTop: '75px',
	minHeight: 'calc(90vh - 75px)',
	display: 'grid',
}));

const StyledBottomGrid = styled(Grid)(({ theme }) => ({
	backgroundSize: 'cover',
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'center',
	position: 'relative',
	minHeight: '180px',
}));

const StyledBoxImage = styled(Box)(({ theme }) => ({
	maxHeight: 75,
	maxWidth: '100%',
	position: 'absolute',
	right: 0,
	top: '10px',
	padding: 1,
}));

export const Banner = () => {
	const entrance = {
		variants: {
			visible: {
				y: 0,
				opacity: 1,
				transition: {
					delay: 0.65,
					ease: 'backOut',
					duration: 2.75,
					staggerChildren: 0.25,
				},
			},
			hidden: { y: '100vh', opacity: 0 },
		},
		initial: 'hidden',
		animate: 'visible',
	};
	gsap.registerPlugin(TextPlugin);

	useLayoutEffect(() => {
		gsap.to('#data', {
			text: {
				value: 'Votre plateforme spécialisée Loi Alur n ° 1 en France',
			},
			duration: 8,
			delay: 1,
			ease: 'none',
			repeat: -1,
			repeatDelay: 5,
		});
	}, []);

	const navigateTo = (newPageUrl) => {
		window.open(newPageUrl, '_blank'); //to open new page
	};
	return (
		<motion.div id='banner' className='full-view' {...entrance}>
			<StyledContainer sx={{ px: 0 }}>
				<Box
					component={'img'}
					sx={{
						position: 'absolute',
						zIndex: -1,
						height: { xs: '100%' },
						width: '100%',
						right: 0,
						left: 0,
						margin: '0 auto',
						objectFit: 'cover',
						opacity: 0.275,
					}}
					src={'/9051320.jpg'}></Box>
				<Grid
					item
					xs={12}
					sx={{
						display: 'flex',
						alignItems: 'center',
						p: 2,
						color: `${theme.palette.primary.dark}`,
					}}>
					<Box>
						<Box
							sx={{
								marginBottom: 2,
								display: { md: 'flex', sm: 'block' },
								alignItems: 'center',
							}}>
							<Box sx={{ display: 'flex !important', alignItems: 'center' }}>
								<StyledTitle
									customText={`M`}
									normalText={`APIM`}
									variant={`h3`}
								/>
							</Box>
							<Typography
								variant={'h2'}
								sx={{ display: { md: 'inherit', xs: 'none' } }}>
								&nbsp;
							</Typography>
							<Box sx={{ display: 'flex !important', alignItems: 'center' }}>
								<StyledTitle
									customText={`F`}
									normalText={`ORMATION,`}
									variant={`h3`}
								/>
							</Box>
						</Box>
						<Typography
							style={{
								color: '#3b5837d9',
								fontWeight: 'bold',
								height: { xs: 250 },
							}}
							variant='h2'
							id='data'></Typography>
					</Box>
				</Grid>
				<StyledBottomGrid
					sx={{
						width: { sm: '(calc(100% + 32px))', md: 'calc(100% + 48px)' },
						marginX: { sm: '0px', md: '-25px' },
					}}
					container
					padding={0.2}>
					<Grid
						item
						xs={12}
						sx={{
							p: 2,
							color: `${theme.palette.primary.dark}`,
							textAlign: 'end',
						}}></Grid>
					<Box
						component={'img'}
						sx={{
							maxHeight: '50%',
							minWidth: '50%',
							maxWidth: '100%',
							position: 'absolute',
							objectFit: 'cover',
							zIndex: 0,
							right: 0,
							bottom: 1,
							left: 0,
							margin: 'auto',
						}}
						src={backgrounds('LOGO_MAPIM_IMMO_SI_NOIR.png')}
					/>
					<Grid
						item
						xs={12}
						onClick={() => navigateTo('https://app.mapim-immo.fr')}
						position={'relative'}
						sx={{
							background: `${theme.palette.primary.info}`,
							opacity: 0.97,
							color: `${theme.palette.primary.light}`,
							borderRadius: { md: '15px 0px 0px 15px', xs: '0px' },
							paddingBottom: 1,
							paddingTop: '60px',
							display: 'flex',
							alignItems: 'end',
							cursor: 'pointer',
							'&:hover': {
								background: `${theme.palette.primary.light}`,
								color: `${theme.palette.primary.dark}`,
							},
						}}
						padding={3}
						md={4}>
						<Typography variant='h4'>Nos modules Loi Alur</Typography>
						<StyledBoxImage
							component={'img'}
							src={homepageImage('icon_formation_blanc.png')}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						onClick={() => navigateTo('https://app.mapim-immo.fr')}
						position={'relative'}
						sx={{
							background: `${theme.palette.primary.main}`,
							opacity: 0.97,
							color: `${theme.palette.primary.light}`,
							paddingBottom: 1,
							paddingTop: '60px',
							display: 'flex',
							alignItems: 'end',
							cursor: 'pointer',
							'&:hover': {
								background: `${theme.palette.primary.light}`,
								color: `${theme.palette.primary.dark}`,
							},
						}}
						padding={3}
						md={4}>
						<Typography variant='h4'>Nos offres formation</Typography>
						<StyledBoxImage
							component={'img'}
							src={homepageImage('icon_web_blanc.png')}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						onClick={() => navigateTo('https://formation.mapim-immo.fr')}
						position={'relative'}
						sx={{
							background: `${theme.palette.primary.light}`,
							opacity: 0.97,
							color: `${theme.palette.primary.dark}`,
							borderRadius: { md: '0px 15px 15px 0px', xs: '0px' },
							paddingBottom: 1,
							paddingTop: '60px',
							display: 'flex',
							alignItems: 'end',
							cursor: 'pointer',
							'&:hover': {
								background: `${theme.palette.primary.dark}`,
								color: `${theme.palette.primary.light}`,
							},
						}}
						padding={3}
						md={4}>
						<Typography variant='h4'>Notre logiciel immobilier</Typography>
						<StyledBoxImage
							component={'img'}
							src={homepageImage('icon_logiciel_blanc.png')}
						/>
					</Grid>
				</StyledBottomGrid>
			</StyledContainer>
		</motion.div>
	);
};
