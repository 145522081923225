import {
	Grid,
	Typography,
	Container,
	styled,
	Box,
	Button,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import {
	backgrounds,
	homepageImage,
	icons,
	staticimages,
} from '../../../constants/staticImages';
import theme from '../../../theme';
import { CustomTitle } from '../../utils';
import { CustomImageGrid } from '../../grid';
import { CustomCenterTitle } from '../../utils/modules/CustomeCenterTitle';
import { StyleCircle } from './StyleCircle';

const StyledContainer = styled(Container)(() => ({
	backgroundSize: 'cover',
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'center',
	position: 'relative',
	marginBottom: '5%',
}));

const StyledBackgroundGrid = styled(Grid)(() => ({
	height: '100%',
	width: '100%',
	zIndex: -1,
	position: 'absolute',
	// direction: 'rtl',
	left: 0,
	top: 0,
	marginTop: '5%',
	display: 'block',
}));

const StyledButton = styled(Button)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	backgroundColor: theme.palette.primary.info,
	color: theme.palette.primary.light,
	fontSize: theme.typography.subtitle1.fontSize,
	borderRadius: '50px',
}));

export const MapimSatisfaction = ({ page }) => {
	const currentPage = page;
	const navigate = useNavigate();
	const handleNavigate = (value) => {
		if (value.target) {
			navigateTo(value.value);
			return;
		}
		navigate(value.value);
	};

	const navigateTo = (newPageUrl) => {
		window.open(newPageUrl, '_blank'); //to open new page
	};

	return (
		<StyledContainer
			maxWidth={false}
			sx={{
				px: 0,
				marginTop: 2,
				backgroundColor: { xs: '#f0f8ffc7', md: 'transparent' },
			}}>
			<Grid
				container
				sx={{
					px: 0,
					marginTop: 2,
					display: 'flex',
					alignItems: (currentPage.reverse && 'end') || 'start',
				}}>
				{(Object.keys(currentPage.object.title).length && (
					<CustomCenterTitle
						props={{
							remoteIcon: currentPage.remote_icon,
							icon: currentPage.icon,
							text: currentPage.object.title,
							reverse: currentPage.reverse,
							centerText: true,
						}}
					/>
				)) ||
					''}
			</Grid>
			<div style={{ textAlign: 'center', width: '60%', margin: 'auto' }}>
				{currentPage.object.description.map((section, index) => (
					<Typography
						key={index}
						sx={{ textAlign: 'center', marginBottom: '30px' }}
						variant={section.type}>
						{section.sentences}
					</Typography>
				))}

				<div
					style={{
						display: 'flex',
						flexWrap: 'wrap',
						justifyContent: 'center',
						width: '100%',
						margin: 'auto',
						gap: '40px',
						marginBottom: '15px',
					}}>
					{currentPage.object.description3.map((section, index) => (
						<div
							key={index}
							style={{
								display: { lg: 'block' },
								width: { xs: '45%' },
							}}>
							<StyleCircle percentage={section.percentage} />
							<Typography
								sx={{
									textAlign: 'center',
									marginTop: '10px',
									fontWeight: 'bold',
									marginBottom: '20px',
									position: 'bottom',
									maxWidth: '100px',
									margin: 'auto',
								}}>
								{section.sentences}
							</Typography>
						</div>
					))}
				</div>
			</div>

			<div style={{ textAlign: 'center', width: '60%', margin: 'auto' }}>
				{currentPage.object.description2.map((section, index) => (
					<Typography
						key={index}
						sx={{ textAlign: 'center', marginBottom: '30px' }}
						variant={section.type}>
						{section.sentences}
					</Typography>
				))}
			</div>
		</StyledContainer>
	);
};
