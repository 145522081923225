import React, { useEffect, useState } from 'react';
import {
	Box,
	Button,
	Input,
	InputLabel,
	TextareaAutosize,
	Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { ExpandLess, MenuBook, QuestionAnswer } from '@material-ui/icons';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { PlayCircle, QuestionMarkRounded } from '@mui/icons-material';
import { PRIMARY } from '../../theme/utils/color';
import { useFormation } from '../../services/useFormation';

import { AuthStorage } from '../../utils/AuthStorage';
import { useQuizz } from '../../services/useQuizz';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../features/useLoading';

const styles = {
	button: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		cursor: 'pointer',
		alignContent: 'center',
		alignItems: 'center',
		backgroundColor: 'rgba(90, 90, 90, .3)',
		color: 'black',
		padding: '10px 0',
		'&:hover': {
			backgroundColor: PRIMARY.green,
			color: 'white',
			padding: '10px 0',
		},
	},
	selected: {
		backgroundColor: PRIMARY.green,
		color: 'white',
		padding: '10px 0',
	},
};

export const QuizzPage = ({ customContent, setCustomContent }) => {
	const { formationUuid } = useParams(); // Récupère le paramètre de l'URL
	const navigate = useNavigate();
	const [isInitialRender, setIsInitialRender] = useState(true);
	const [isLessonPage, setIsLessonPage] = useState(true);
	const [formation, setFormation] = useState();
	const [currentQuizz, setCurrentQuizz] = useState();
	const [allQuizzs, setAllQuizzs] = useState([]);
	const [currentAnswer, setCurrentAnswer] = useState();
	const [quizzAnswers, setQuizzAnswers] = useState([]);

	const dispatch = useDispatch();
	const handleSubmitResponse = (quizz, answer) => {
		// Enregistrer la réponse ici
		if (!quizzAnswers.filter((item) => item.question_id === quizz.id)) {
			const oldAnswers = quizzAnswers;
			oldAnswers.push({ question_id: quizz.id, answer });
			setQuizzAnswers(oldAnswers);
		}
		nextQuizz();
		setCurrentAnswer(false);
	};
	const handleRadioChange = (theQuizz, response) => {
		let oldAnswers = quizzAnswers;
		if (
			oldAnswers.filter((quizz) => quizz.question_id === theQuizz.id).length !==
			0
		) {
			oldAnswers = oldAnswers.map((q) => {
				if (q.question_id === theQuizz.id) q.answer = response;
				return q;
			});
		} else {
			oldAnswers.push({
				question_id: theQuizz.id,
				answer: response,
			});
		}
		setQuizzAnswers(oldAnswers);
		setCurrentAnswer(true);
	};
	const handleCheckboxChange = ({ target }, quizz) => {
		/**
		 * If it's a checkbox, we have to register all response
		 */
		let oldAnswers = quizzAnswers;
		let id = target.id;
		if (target.checked) {
			if (
				oldAnswers.filter((answer) => answer.question_id === quizz.id)
					.length === 0
			) {
				oldAnswers.push({
					question_id: quizz.id,
					answer: id,
				});
			} else {
				const currentResponse = oldAnswers.filter(
					(answer) => answer.question_id === quizz.id
				);
				oldAnswers = oldAnswers.map((answer) => {
					if (answer.question_id === quizz.id) {
						answer.answer = currentResponse[0]
							? currentResponse[0].answer + '|' + id
							: id;
					}
					return answer;
				});
				oldAnswers = oldAnswers.filter((answer) => {
					return answer.answer !== '';
				});
			}
		} else {
			oldAnswers = oldAnswers.map((answer) => {
				if (answer.question_id === quizz.id) {
					answer.answer = answer.answer.replace(id, '');
					answer.answer = answer.answer.replace(/^(\|)+|(\|)+$/g, '');
					answer.answer = answer.answer.replace('||', '|');
				}
				return answer;
			});
		}

		setQuizzAnswers(oldAnswers);
		setCurrentAnswer(
			target.checked === true ||
				oldAnswers.filter(
					(answer) => answer.question_id === quizz.id && answer.answer === ''
				).length === 0
		);
	};

	const sendQuizz = async () => {
		const quizzResponse = quizzAnswers.filter((quizz) => quizz != null);
		const response = await useQuizz.takeQuizz(quizzResponse);
		if (response.status === 201) window.location.href = '/dashboard';
	};
	const getOneFormation = (formationId) => {
		useFormation
			.getByUuidFormation(formationId)
			.then((response) => {
				setFormation(response.data);
				setCurrentQuizz(response.data.questions[0]);
				let allQuizz = response.data.questions;
				setAllQuizzs(sortChapters(allQuizz));
			})
			.catch((e) => {
				console.log(e);
			});
	};
	const sortChapters = (chapters) =>
		chapters.sort((a, b) => a.question_order - b.question_order);

	const nextQuizz = () => {
		const nextQuizz = allQuizzs[allQuizzs.indexOf(currentQuizz) + 1];
		setCurrentQuizz(nextQuizz);
		// if (!nextQuizz) window.location.href = '/dashboard';
	};

	useEffect(() => {
		if (!formation) {
			getOneFormation(formationUuid);
			setTimeout(() => dispatch(setLoading(false)), 1500);
		}
		setIsInitialRender(false);
		setCustomContent(
			currentQuizz && formation && (
				<>
					<Box
						sx={{
							display: 'flex',
							padding: '5px',
							alignItems: 'center',
						}}>
						<IconButton
							onClick={() => {
								setCustomContent(null);
								navigate('/dashboard/courses');
							}}>
							<ExpandLess style={{ transform: 'rotate(-90deg)' }} />
						</IconButton>
						<Typography sx={{ fontSize: '16px' }}>{formation.title}</Typography>
					</Box>
					<Box
						sx={{
							display: 'flex',
							marginBottom: '20px',
							width: '100% !important',
						}}>
						<Box
							sx={styles.button}
							className={isLessonPage ? 'dashboard-sidebar-focused' : ''}
							onClick={() => setIsLessonPage(true)}>
							<QuestionMarkRounded />
							<Typography sx={{ fontSize: '24px' }}>Questionnaire</Typography>
						</Box>
					</Box>
					{isLessonPage && (
						<Box
							sx={{
								margin: '0 20px',
								overflowY: 'scroll',
							}}>
							{sortChapters(formation.questions).map((q, i) => {
								return (
									<div key={i}>
										<Typography
											sx={{
												cursor: 'pointer',
												fontSize: '18px',
												fontWeight: 'bold',
												marginBottom: '10px',
											}}>
											Q{q.question_order}. {q.question_title}
										</Typography>
									</div>
								);
							})}
						</Box>
					)}
				</>
			)
		);
	}, [
		isInitialRender,
		setCustomContent,
		navigate,
		isLessonPage,
		formationUuid,
		allQuizzs,
		currentAnswer,
		setCurrentAnswer,
		currentQuizz,
		setIsLessonPage,
		formation,
	]);

	return (
		currentQuizz && (
			<div style={{ padding: '20px' }}>
				<Typography>
					Q{currentQuizz.question_order}. {currentQuizz.question_title}
				</Typography>
				{currentQuizz && (
					<Box
						sx={{
							padding: '15px',
							display: 'flex',
							flexWrap: 'wrap',
							gap: '20px',
						}}>
						{currentQuizz.answers.split('|').map((answer, i) => {
							return (
								currentQuizz.answers && (
									<Box
										key={answer + '_' + i}
										sx={{
											display: 'flex',
											alignItems: 'center',
											gap: '5px',
										}}>
										{currentQuizz.answers &&
											currentQuizz.question_type === 1 && (
												<Input
													onChange={() =>
														handleRadioChange(currentQuizz, answer)
													}
													style={{ margin: 0 }}
													type='radio'
													name={`answer-${currentQuizz.answers}`}
													value={answer}
												/>
											)}
										{currentQuizz.answers &&
											currentQuizz.question_type === 0 && (
												<Input
													onChange={(event) =>
														handleCheckboxChange(event, currentQuizz)
													}
													style={{ margin: 0 }}
													id={answer}
													type='checkbox'
													name={`answer-${currentQuizz.answers}`}
												/>
											)}

										<InputLabel className='input-checkbox-quiz'>
											{answer}
										</InputLabel>
									</Box>
								)
							);
						})}
					</Box>
				)}
				{allQuizzs[allQuizzs.length - 1] !== currentQuizz && (
					<Button
						color='success'
						variant='contained'
						disabled={!currentAnswer}
						onClick={() => handleSubmitResponse(currentQuizz, currentAnswer)}>
						Valider
					</Button>
				)}

				{allQuizzs[allQuizzs.length - 1] === currentQuizz && (
					<Button
						color='success'
						variant='contained'
						disabled={!currentAnswer}
						onClick={() => {
							handleSubmitResponse(currentQuizz, currentAnswer);
							sendQuizz();
						}}>
						Valider votre quizz
					</Button>
				)}
			</div>
		)
	);
};
