import React, { useMemo, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Routes, Route, useLocation } from 'react-router-dom';

import { SiteRoute } from './modules/SiteRoute';
import AuthenticatedRoute from './modules/AuthenticatedRoute';
import { DashboardRoute } from './modules/DashboardRoute';
import { AuthenticationRoute } from './modules/AuthenticationRoute';
import { AuthStorage } from '../utils/AuthStorage';
import { createGraphqlClient } from '../api/graphqlClient';
import { GraphClientContext } from '../contexts/modules/GraphClientContext';
import LoadingComponent from '../components/block/modules/Loading';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../features/useLoading';

export const MainRouter = () => {
	const tokenFrom = AuthStorage.isAuth()?.token;
	let location = useLocation();
	const isLoading = useSelector((state) => state.loading.isLoading);
	// const [isLoad, setIsLoad] = useState(isLoading);
	const dispatch = useDispatch();

	React.useEffect(() => {
		dispatch(setLoading(true));
	}, [location]);
	// const client = useMemo(() => {
	//     return createGraphqlClient(tokenFrom);
	// }, [tokenFrom]);

	return (
		<HelmetProvider>
			<Helmet>
				<meta charSet='utf-8' />
				<title>MAPIM-IMMO</title>
				<meta name='description' content={''} />
			</Helmet>
			{isLoading && (
				<div
					style={{
						width: '100%',
						height: '100vh',
						position: 'fixed',
						top: 0,
						left: 0,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						zIndex: 50000,
						backgroundColor: 'rgba(15, 15, 15, 0.97)',
					}}>
					<LoadingComponent />
				</div>
			)}
			<Routes>
				<Route path='/*' element={<SiteRoute />} />
				<Route path='/auth/*' element={<AuthenticationRoute />} />
				<Route
					path='/dashboard/*'
					element={<AuthenticatedRoute children={<DashboardRoute />} />}
				/>
			</Routes>
		</HelmetProvider>
	);
};
