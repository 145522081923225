import { Grid, Typography, Box, styled } from '@mui/material';
import React, { useLayoutEffect } from 'react';
import { StyledTitle } from '../../block/modules/StyledTilte';
import { homepageImage, icons } from '../../../constants/staticImages';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { StyledCenteredTitle } from '../../block/modules/StyleCenteredTitle';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';

const StyledBoxImage = styled(Box)(() => ({
	maxHeight: 75,
	width: '100%',
	left: 0,
	top: 0,
	padding: 1,
	transform: 'rotate(5deg)',
}));
gsap.registerPlugin(ScrollTrigger);

export const CustomCenterTitle = ({ props }) => {
	const { icon, text, reverse, centerText, remoteIcon } = props;

	useLayoutEffect(() => {
		const tl = gsap.timeline({
			scrollTrigger: {
				trigger: '#text-satisfaction',
				toggleActions: 'restart restart restart reset',
			},
		});
		gsap.from('.text-satisfaction', {
			x: 500,
			opacity: 0,
		});
		gsap.to('.text-satisfaction', {
			duration: 2,
			x: 0,
			opacity: 1,
			scrollTrigger: {
				trigger: '#text-satisfaction',
				toggleActions: 'restart restart restart reset',
			},
		});
	});
	// const { ref, inView } = useInView({
	// 	threshold: 1,
	// });
	// const imageAnimation = {
	// 	variants: {
	// 		visible: {
	// 			rotate: '360deg',
	// 			transition: { delay: 0.5, duration: 1.15 },
	// 		},
	// 		hidden: {
	// 			rotate: 0,
	// 		},
	// 	},
	// 	initial: !inView ? 'visible' : 'hidden',
	// 	animate: inView ? 'visible' : 'hidden',
	// };
	// const titleAnimation = {
	// 	variants: {
	// 		visible: () => ({
	// 			x: 0,
	// 			opacity: 1,
	// 			transition: { duration: 1.15, delay: 0.5 },
	// 		}),
	// 		hidden: {
	// 			x: '50vh',
	// 			opacity: 0,
	// 		},
	// 	},
	// 	initial: !inView ? 'visible' : 'hidden',
	// 	animate: inView ? 'visible' : 'hidden',
	// };

	return (
		<Grid
			item
			id='text-satisfaction'
			xs={12}
			sx={{ position: 'relative', p: 2, marginBottom: 10 }}
			display={'flex'}
			justifyContent={!centerText ? 'inherit' : 'center'}>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					flexDirection: 'column',
				}}>
				<div className='text-satisfaction'>
					<StyledCenteredTitle
						customText={`${text.upper_title.charAt(0)}`}
						normalText={`${text.upper_title.replace(/^./, '')}`}
						variant={`h3`}
						fontSize='50px'
					/>
				</div>
				<div className='text-satisfaction'>
					<StyledCenteredTitle
						fontSize='50px'
						customText={`${text.lower_title.charAt(0)}`}
						normalText={`${text.lower_title.replace(/^./, '')}`}
						variant={`h3`}
					/>
				</div>
			</Box>
			<div>
				{icon && (
					<StyledBoxImage
						component={'img'}
						sx={{ transform: 'rotate(20deg)' }}
						src={remoteIcon ? homepageImage(icon) : icon}
					/>
				)}
			</div>
		</Grid>
	);
};
